%answer-position {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    width: 30px;
    height: 30px;
    background: #005D92;
    color: white;
    top: 107%;
    padding-top: 4px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
}

app-order-matching {
    display: flex;
    @extend %activities-card;
    height: inherit;
    min-height: 100%;
    flex-direction: column;

    .mat-card.activities-card {
        display: flex;
        flex-direction: column;
        align-items: center;

        .mat-card-content {
            max-width: 100%;
            padding: 0 20px 30px;
            //@media (max-height: 768px) { // si bug vh
            //    width: 200px;
            //}
            width: 51vh;
        }
    }


    app-consignes {
        margin-bottom: 5px !important;
    }

    .mat-card mat-card-content.mat-card-content {
        align-items: center;
    }

    .answer-position {
        @extend %answer-position;

        &.current {
            background: #FED501;
            color: #005D92;
        }
    }

    #container-for-answers-selected,
    #container-for-answers-available, cssOrderSelectAndAvaible {
        display: flex;
        column-gap: 12px;
        flex-wrap: wrap;
        flex: 1 1 55px;
        margin: 0;
        position: relative;

        > div {
            overflow: hidden;
            &.currently-correct {
                padding: 0;
                border: 4px solid #89E30C;
            }
            // answer is good and for a short moment we have a green border
            &.was-correct &.pristine {
                padding: 0;
                border: 4px solid #89E30C;
                cursor: not-allowed;
            }
            // answer is good we never have to click on it again
            &.was-correct {
                padding: 0;
                opacity: 0.3;
                cursor: not-allowed;
            }

            &.incorrect {
                padding: 0;
                border: 4px solid #3A3A3A;
            }
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    #container-for-answers-selected {
        > div {
            &.incorrect {
                border: 4px solid transparent !important;
            }
        }
    }

    #container-for-answers-selected {
        > div {
            &.incorrect {
                border: 4px solid transparent !important;
            }
        }
    }

    #container-for-answers-selected, cssOrderMatching76 {
        flex: 0;
        display: grid;

        grid-template-rows: 1fr;
        grid-column-gap: 12px;
        grid-row-gap: 0;
        width: 100%;
        padding-bottom: 30px;

        &.grid-inline-4 {
            width: 80%;
            grid-template-columns: repeat(4, 1fr);
        }
        &.grid-inline-2 {
            width: 50%;
            grid-template-columns: repeat(2, 1fr);
            padding-bottom: 50px;
        }

        > div {
            background: rgba(15, 169, 255, 0.1);
            border-radius: 10px;
            padding-top: 100%;
            overflow: visible;
            position: relative;

            img {
                height: 100%;
                width: 100%;
                border-radius: 5px;
            }
        }

        .bad-answer {
            &:before {
                background: #3A3A3A !important;
            }
        }
    }

    #container-for-answers-available {

        width: 100%;
        max-width: 430px;
        place-content: start space-between;
        flex-wrap: wrap;

        margin-top: auto !important;
        margin-bottom: auto;

        &.columns-1 {
            justify-content: center;
        }

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;


        max-width: 100%;

        &.grid-square-4 > div, &.grid-square-2 > div {
            width: calc(50% - 6px);
            margin-bottom: 12px;
        }

        > div {
            overflow: hidden;
            border: 4px solid #0FA9FF;
            border-radius: 10px;
            box-shadow: 0 3px 20px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

            img {

                width: 100%;
                height: auto;
                border-radius: 0;
            }
        }
    }

    .mat-card {
        &.two-columns-layout {

            #container-for-answers-selected, cssOrderMatchingSelected166 {
                width: 100%;
                flex-direction: column;
                display: flex;

                > div {
                    display: flex;
                    flex: auto;
                    background: rgba(15, 169, 255, 0.1);
                    margin-bottom: 5px;
                    position: relative;
                    padding: 0;
                    border: none;
                    border-radius: 5px;
                    height: 30px;
                    margin-left: 16px;
                    width: calc(100% - 16px);

                    &:before {
                        display: none;
                    }

                    .answer-position {
                        display: flex;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        font-weight: 700;
                        size: 18px;
                        line-height: 21px;
                        align-items: center;
                        left: -15px;
                    }

                    label {
                        width: 100%;
                        margin-left: 23px;
                        display: flex;
                        align-content: center;
                        align-items: center;
                        margin-right: 10px;
                    }

                    &.currently-correct {
                        background: rgba(137, 226, 13, 0.2);
                        color: #005D92;
                        font-weight: bold;
                    }

                    &.was-correct {
                        background: rgba(137, 226, 13, 0.2);
                        color: #005D92;
                        font-weight: bold;
                    }

                    &.incorrect {
                        background: rgba(58, 58, 58, 0.1);
                    }
                }
            }

            #container-for-answers-available {
                place-content: center;

                &.columns-2 {
                    flex-direction: column;
                    grid-column-gap: 0 !important;
                    grid-row-gap: 0 !important;
                }

                button {
                    @extend %button-image;
                    @extend %button-half;
                    @extend %button-half-blue;
                    border: none;
                    margin: 0 0 8px 0 !important;
                    justify-content: center;
                    padding: 0 !important;
                    width: auto;
                    flex: 0 1 47.8%;

                    &::before {
                        padding-top: calc(100% / 2.58);
                    }

                    .mat-button-wrapper {
                        position: relative;
                        align-self: stretch;
                         > span {
                             position: absolute;
                             top: 40%;
                             transform: translateY(-50%);
                         }
                    }

                    &[disabled="true"], &.mat-raised-button[disabled][disabled] {
                        // color: rgba(255, 255, 255, 1);
                        color: white;
                    }

                    &.pristine { // perfect
                        //@extend %button-half-green;
                    }

                    &.was-correct {
                        opacity: 0.3;
                        cursor: not-allowed;
                    }

                    &.incorrect {
                        @extend %button-half-grey;
                        color: white;
                    }

                    &.currently-correct {
                        @extend %button-half-green;
                        color: white;
                    }
                }
            }
        }
    }
}

