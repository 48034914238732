// center spinner
.spinner-container {
    &.center {
        display: block;
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        .spinner-wrapper {
            display: block;
            position: relative;
            width: 100%;
            min-height: 100px;
            height: 100px;

            .mat-spinner {
                margin: 0 auto;
            }
        }
    }
}
.spinner-wrapper {
    display: block;
    position: relative;
    width: 100%;
    min-height: 100px;
    height: 100px;

    .mat-spinner {
        margin: 0 auto;
    }
}