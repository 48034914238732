app-analytics {
    form{
        label {
            color: #757575;
            font-size: 20px;
            span {
                font-size: 14px;
            }
        }
    }
}
