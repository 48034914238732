/* MAP HTML/CSS
* <fuse-app-lessons>
*   |- .page-layout.simple
*       |- app-stepper-wrapper
*           |- app-generic-stepper.sidebar
*               |- .content
*           |- .center .js-latex-is-open
*               |- .header //fuse-app-lessons-header
*                   |- .header-top // ({{lessonTitle}} || {{activityTitle}}) + .toolbar
*                   |- .header-bottom // {{stepTitle}}
*               |- <content>
*                    |- [[ .activities-card.inner-scroll ]]
*                        |- [[ .mat-card-header ]]
*                        |- [[ .mat-card-content ]]
*                        |- [[ .mat-card-footer // .gradePercentil {{generic.score}} ]]
*               |- .footer // fuse-app-lessons-content-footer
*                        |- [[ app-user-action-button ]]
 */
@use '~@angular/material' as mat;

$validated-color: #008A9C; //  cyan 600 #00ACC1
$warning-color: #ED6723;
$success-color: #679A00;
$error-color: darkred;
//.mat-toolbar-row,.mat-toolbar-single-row
//node_modules/@angular/material/bundles/material-toolbar.umd.js:123
$mat-toolbar-row-height: 64px;
$fuse-app-lessons-header: 56px;
$app-generic-stepper-height: 70px;
$app-generic-stepper-width: 70px;
$keyboard-grid-item-tab-height: 6vh;
$keyboard-grid-item-height: 8vh;
$latex-keyboard-height: $keyboard-grid-item-tab-height + ( $keyboard-grid-item-height * 5);

// Calculate toolbarless header height
$top-toolbar-row-and-lessons-header-height: $mat-toolbar-row-height + $fuse-app-lessons-header;

%btn-container {
    display: flex;
    margin: 0 20px 20px 0;
    position: relative;
    align-items: center;

    .counting-circle {
        width: 37px;
        height: 37px;
        background-color: #24b7c7;
        border-radius: 50%;
        line-height: 37px;
        text-align: center;
        font-size: 28px;
        font-weight: 500;
        color: #fff;
        font-family: Roboto;
        margin-right: 0.5vw;
    }


    .mat-mini-fab {
        z-index: 1;
        position: absolute;
        top: -13px;
        right: -13px;
        width: 26px;
        height: 26px;

        // > _activities-module-activities-card.scss
        &.success {
            // success (use default material attribute to have default style)
            background-color: #679a00;
        }

        &.warning {
            // warning
            background-color: #ed6723;
        }

        &.error {
            // error
            background-color: darkred;
        }
    }

    button {
        display: flex;
        flex: 60%;
        min-height: 82px;
        padding: 10px 10px 10px 20px;
        border: 3px solid #008a9c;
        border-radius: 4px;
        color: #000000;
        font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        background-color: #ffffff;
        text-align: left;

        // error messages
        &.correctAnswer {
            border-color: #679a00;
        }

        &.wrongAnswer {
            border-color: darkred;
        }

        &.answerMissed {
            border-color: #ed6723;
        }
    }
}

%row-btn-container {
    flex-direction: column-reverse;
    flex: auto;
    align-items: stretch;
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    margin-bottom: 0;

    .counting-circle {
        align-self: center;
        margin-right: 0;
        margin-top: 1vh;
    }

    .orderlist {
        flex: auto;
        justify-content: center;
        padding: 0;
        position: relative;
    }
}


// TODO Next Refacto palettes couleurs
// $validated-color: mat-color($primary); //  cyan 600 #00ACC1
// $warning-color: mat-color($secondary);
// $success-color: mat-color($accent);
// $error-color: mat-color($warn);

@import "~@fuse/scss/fuse";

@import '_editors';
@import '_activities-card';
@import '_forms-list';
@import '_lessons';
@import '_player-components';

/* ***************************************************************************** */
// ----------- confirm-dialog
/* ***************************************************************************** */
// > mat-dialog-actions >
app-error-reporting-trigger-button {
    min-height: 65px;
}

fuse-confirm-dialog {

    .mat-dialog-actions {
        position: relative;

        app-error-reporting-trigger-button {
            padding-top: 50px;
        }

        .mat-fab {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

fuse-app-lessons-tab {

    .mat-paginator {
        padding-right: 70px;
    }

    .cardLayout-wrapper {
        flex: 1 1 100%;
        flex-direction: row;
        display: flex;
        padding: 36px;
        max-width: 50%;
        @include media-breakpoint('gt-lg') {
            max-width: 33%;
        }
    }

    .add-file-button--cardLayout {
        flex: 1;
        box-sizing: border-box;
        min-height: 258px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: stretch;
        color: mat.get-color-from-palette($primary);
        font-size: 22px;
        font-weight: 700;
        line-height: 24px;
        background-color: white;
        border: 15px solid mat.get-color-from-palette($primary);
        border-radius: 20px;

        .mat-icon {
            width: 42px;
            height: 42px;
            margin-bottom: 15px;
        }
    }
}

edit-lesson {
    .edit-lesson-actions {
        padding-top: 32px;
        padding-bottom: 32px;
    }
}


fuse-app-activities-list {

    .mat-column-type .mat-icon {
        width: 32px;
        height: 32px;

        path {
            fill: none;
            stroke: mat.get-color-from-palette($primary);
        }
    }

}

app-dialog {
    .activities-list-dialog & {
        mat-card-actions {
            button {
                background-color: mat.get-color-from-palette($accent);
                color: #fff;

                path {
                    fill: #fff;
                }
            }
        }
    }
}

.lesson-form-dialog {
    .mat-toolbar-row {
        justify-content: space-between;
    }
}


.mat-checkbox-indeterminate, .mat-checkbox-checked {

    &.mat-accent .mat-checkbox-background, erasme {
        background-color: mat.get-color-from-palette($primary);
    }

}


// Elements transverse
video, image { //TODO transverse > media.scss
    max-width: 100%;
}


dialog_component, .mat-dialog-content {
    &.js-latex-is-open {
        max-height: 32vh; // HACK Valeur en dure précisément récupérée depuis la taille du keyboard (65vh)
    }
}

app-form-player { // Exclusif savanturier à généraliser
    .card-formplayer {
        &.mat-card {
            width: 100%;
            margin: 25px 50px 50px 50px;
            box-shadow: none;

            > .mat-card-header {
                width: calc(100% + 48px);
                margin: -24px -24px 0 -24px;

                border-left: 1px solid #00D3CC;
                border-right: 1px solid #00D3CC;
                border-top: 1px solid #00D3CC;
                border-top-left-radius: 18px;
                border-top-right-radius: 18px;

                .mat-card-header-text, .mat-card-title, .mat-card-subtitle {
                    display: block;
                    margin: 0;
                    flex: 1 0 auto;
                }

                .mat-card-header-text {
                    background-color: transparent; // TODO change transverse theme property
                    width: 100%;

                    .mat-card-title {
                        padding: 15px 35px;
                        color: #00d3cc;
                        font-family: 'nexabold';
                        font-size: 18px;
                        letter-spacing: 0.15px;
                        line-height: 21px;

                        background-color: rgba(0, 211, 204, 0.1);
                        border-top-left-radius: 15px;
                        border-top-right-radius: 15px;
                    }

                    .mat-card-subtitle {
                        margin: 25px 49px 15px 35px;
                        padding: 0 0 10px 0;
                        background-color: #fff;
                        font-family: 'nexa';
                        font-size: 14px;
                        line-height: 16px;
                        color: #000000;
                        letter-spacing: .15px;

                        border-bottom: 1px solid #979797;
                    }
                }

                .mat-button {
                    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
                    margin-right: 20px;
                    background-color: #FFFFFF;
                    border-radius: 50%;
                    min-width: auto;
                    width: 32px;
                    height: 32px;
                    padding: 0;
                    line-height: 32px;
                    font-family: 'nexabold';
                    position: absolute;
                    right: -10px;
                    top: 10px;

                    .mat-icon {
                        min-width: 18px;
                        min-height: 18px;
                        width: 18px;
                        height: 18px;
                        line-height: 18px;
                        font-size: 18px;
                        font-weight: bold;
                    }
                }
            }

            .mat-card-actions {
                text-align: center;

                .mat-button {
                    border: 1px solid #00d3cc;
                    border-radius: 30px;
                    width: 150px;
                    height: 35px;
                    background-color: #00d3cc;
                    color: #ffffff;
                    padding: 0;
                    font-size: 14px;
                    line-height: 23px;
                    font-family: 'nexa';
                    letter-spacing: 1px;
                }
            }
        }

        > .mat-card-content {
            padding: 40px 13px 20px 35px;
            margin: 0 -24px 60px -24px;
            border-left: 1px solid #00D3CC;
            border-right: 1px solid #00D3CC;
            border-bottom: 1px solid #00D3CC;

            > .content {
                height: 466px;
                overflow-y: scroll;
                padding: 0 36px 0 0;
                position: relative;

                .ng-star-inserted { // container individuel des exercices .ng-star-inserted
                    position: relative;
                }

                .ps__rail-y { // le rail
                    opacity: 1;
                    display: block;
                }

                .ps__rail-y, .ps__rail-x {
                    background-color: #e5fbfa;
                }

                .ps__thumb-x, .ps__thumb-y {
                    background-color: #00D3CC;

                    &:hover {
                        background-color: #00c4d0;
                    }
                }
            }

            app-consignes .container {
                margin: 0 0 21px 0;
                border-bottom: 1px solid #979797;
                font-family: 'nexabold';
                font-size: 18px;
                line-height: 21px;
                flex-direction: row;
                justify-content: space-between;
                box-sizing: border-box;
                display: flex;
                flex: 1 1 0;

                .instruction, .wording {
                    margin: 0;

                    p {
                        margin: 0;
                        padding: 0;
                        line-height: 30px;
                        display: flex;
                    }

                    img {
                        max-width: none;
                        max-height: 40px;
                        margin: 0 10px;

                        // v display: flex;
                        display: inline-flex;
                        align-items: center;
                    }
                }

                button {
                    align-self: flex-end;
                    display: inline-block;
                }
            }

            .userActionStyle {
                display: flex;

                mat-radio-group {
                    float: right;

                    .activityValidated {
                        margin: 15px 10px;
                        border: 1px #5BFFEE;
                        color: #5BFFEE;
                    }

                    .activitiyRemainedToValidate {
                        margin: 15px 10px;
                        color: #FBFF4F;
                    }

                    .noCommentsOnActivity {
                        border: 1px #BFBFBF;
                        margin: 15px 10px;
                        color: #BFBFBF;
                    }

                    ::ng-deep .mat-radio-label-content {
                        border-radius: 27px;
                        border: 2px solid;
                        padding: 0 15px;
                        margin: 0 5px;
                    }

                    //TODO supprimer ::ng-deep si possible
                }
            }
        }

        .textareaContent {
            width: calc(100% + 48px);
            margin: -24px -24px 0 -24px;
            height: 50px;
            border: 1px solid #00D3CC;
            border-right: 1px solid #00D3CC;
            border-top: 1px solid #00D3CC;
            padding: 15px;
        }

        .activities-card {
            .mat-card-header {
                display: none;

                .mat-card-header-text {
                    flex: 1 0 auto;
                    margin: 0;
                    color: #4A4A4A;

                    .mat-card-title {
                        flex: 1 0 auto;
                        margin: 0;
                    }
                }
            }

            .mat-card-content {
                @include mat.elevation(2);
                padding: 10px 0 60px 0;

                app-consignes {
                    margin-bottom: 0;
                }
            }

            app-consignes button {
                display: inline-block;
                position: relative;
                padding-left: 30px;

                &:before {
                    content: ' ';
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-position: center;
                    left: 0;
                    height: 100%;
                    position: absolute;
                }

                .material-icons {
                    display: none;
                }
            }

        }

        .activities-card-status-btn {
            /* TODO - Trouver une solution alternative pour le positionnement avec le ngIf sur l'appel du component */
            margin: -75px 0 40px;
            text-align: right;
            padding-right: 25px;

            .mat-radio-button {

                &.validated, &.tobevalidated, &.nocomments {
                    margin-left: 8px;
                    margin-right: 8px;

                    .mat-stroked-button {
                        border-radius: 20px;
                        background-repeat: no-repeat;
                        padding-left: 45px;
                        background-position: 10px;
                        background-size: 25px;
                    }
                }

                &.validated .mat-stroked-button {
                    border: 1px solid #00E998;
                    color: #00E998;
                    background-image: icon('chat_happy.svg');
                }

                &.tobevalidated .mat-stroked-button {
                    border: 1px solid #DFB900;
                    color: #DFB900;
                    background-image: icon('chat_sad.svg');
                }

                &.nocomments .mat-stroked-button {
                    border: 1px solid #4A4A4A;
                    color: #4A4A4A;
                    //background-image: icon('chat_nocomments.svg');
                }
            }
        }

        app-qcm {
            app-consignes button:before {
                background-image: url(/assets/savanturiers/icons/qcu.svg);
            }
        }

        app-qcu {
            mat-card-content {
                .mat-radio-group {
                    display: block;
                    margin: 0;

                    .mat-radio-button {
                        border: none;
                        margin-bottom: 0;
                    }
                }
            }

            app-consignes button:before {
                background-image: url(/assets/savanturiers/icons/qcm.svg);
            }
        }

        app-short-answer {
            app-consignes button:before {
                background-image: url(/assets/savanturiers/icons/free_answer.svg);
            }

            .activities-card {
                flex-direction: column;
            }

            .mat-card-content {
                app-consignes,
                & > * {
                    margin: 0;
                }

                flex: 0 0 auto;
                margin-left: 0;

                textarea {
                    width: calc(100% - 30px);
                    margin: 0 15px;
                    border: none;
                }
            }
        }
    }
}

fuse-app-models-tab, app-form-activities {
    .header {
        h2 {
            color: #979797;
        }
    }
}

app-form-activities {
    .list-item.empty {
        border: 3px dashed gray;
        border-radius: 35px;
    }
}

app-qcm-editor, app-qcu-editor, app-short-answer-editor {
    .header {
        color: #979797;
    }

    .mat-form-field-flex {
        align-items: flex-end;

        input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset;
            transition: background-color 5000s ease-in-out 0s;
        }


    }

    .mat-input-element {
        font-size: 24px;
        font-family: 'nexabold';
        margin-top: -0.19em;
    }

    .mat-form-field-prefix, .mat-form-field-suffix {
        padding-bottom: 10px;

        & > span {
            display: flex;
            align-items: center;
        }
    }

    .mat-form-field-prefix {
        padding-right: 5px;
    }
}

app-lesson-activities, app-form-activities, app-multi-editor, app-multimedia-editor {
    .tools .header {
        //background: image("backgrounds/banners/editor.svg") no-repeat 100% 0;
        background-size: auto 100%;
    }

    .tools h3.title {
        line-height: 34px;
        font-size: 24px;
        font-weight: bold;
        margin: 0;
    }

    .tools .sub-title {
        line-height: 25px;
        font-size: 18px;
    }

    .tools .list-item {
        padding: 30px 0;
    }

    .tools .mat-form-field-wrapper {
        button {
            display: inline-flex;
        }

        .mat-checkbox {
            margin-right: 15px;
            display: inline-flex;
        }
    }

    .tools .answer .mat-form-field-label {
        margin-left: 78px;
    }

    .tools .activity-item {
        height: 68px;
        position: relative;
        background: rgba(151, 151, 151, 0.78);
        border-radius: 100px;
        width: 100%;
        margin-bottom: 15px;
        color: white;
        font-size: 24px;

        .ItemTitle, .activity-main-item {
            padding: 0 10px;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        mat-icon {
            background: white;
            color: rgba(0, 0, 0, 0.5);
            border-radius: 100px;
        }

        mat-icon:hover {
            color: rgba(0, 0, 0, 1);
        }

        .itemType mat-icon {
            height: 44px;
            width: 44px;
            padding: 10px;
        }

        .itemType mat-icon:hover {
            color: rgba(0, 0, 0, 0.5);
        }

        .handle mat-icon {
            background: none;
            color: white;
        }

        .actionBtns mat-icon {
            margin: 0 0 0 9px;
            height: 32px;
            width: 32px;
            padding: 4px;
        }
    }
}


app-multimedia-editor {
    .activity-item {
        img {
            flex: 0;
            max-height: 100%;
            width: auto;
        }
    }
}

app-stepper-wrapper {
    .row > .center.js-latex-is-open,
    .row-reverse > .center.js-latex-is-open {
        //max-height: 39vh; // HACK Valeur en dure précisément récupéré depuis la taille du keyboard (59vh)
        max-height: calc(100vh - (#{$top-toolbar-row-and-lessons-header-height} + #{$latex-keyboard-height} - 39px));
        // récupéré depuis la taille du keyboard (59vh)
    }

    .column > .center.js-latex-is-open {
        //max-height: 39vh; // HACK Valeur en dure précisément récupéré depuis la taille du keyboard (59vh)
        max-height: calc(100vh - (#{$top-toolbar-row-and-lessons-header-height} + #{$latex-keyboard-height} - 39px));
        // récupéré depuis la taille du keyboard (59vh)
    }

    .column-reverse > .center.js-latex-is-open {
        max-height: calc(100vh - (#{$top-toolbar-row-and-lessons-header-height} + #{$latex-keyboard-height} + #{$app-generic-stepper-height} - 39px));
        margin-bottom: auto;
    }
}


fuse-app-lessons #lessons {
    flex-direction: row; // nécessaire pour que app-stepper-wrapper occupe 100% height
    // propriété implémenté par la class .right-sidebar mais ici la position est variable

    // L'élément intercallé doit récupérer les propriétées
    app-stepper-wrapper {
        display: flex; // content > *:not(router-outlet)
        width: 100%; // content > *:not(router-outlet)
        flex: 1 1 0%; //  content.inner-scroll > *:not(router-outlet)
        min-width: 0;
        min-height: 0;
        // gère les la dimension du stepper en fonction de sa place
        // Haut ou bas
        & > .column, & > .column-reverse {
            > .sidebar {
                height: $app-generic-stepper-height;
                min-height: $app-generic-stepper-height;
                max-height: $app-generic-stepper-height;
            }
        }

        // droite ou gauche
        & > .row, & > .row-reverse {
            > .sidebar {
                width: $app-generic-stepper-width;
                min-width: $app-generic-stepper-width;
                max-width: $app-generic-stepper-width;
            }
        }

    }

    .header {
        height: inherit;
        min-height: inherit;
        max-height: inherit;
        padding: 8px;

        .header-top {
            flex-direction: row;
            box-sizing: border-box;
            display: flex;
            max-height: 100%;
            place-content: center space-between;
            align-items: center;
        }
    }

    .center {
        box-shadow: none;
    }

    .footer {
        background-color: map_get($mat-white, 500);
    }

    .fuse-app-lessons-header {
        color: #fff;
        background: mat.get-color-from-palette($primary);
    }

    app-user-action-button {
        padding: 0;
        margin: 0;
    }

}

app-generic-stepper .steps {
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;

    .ps__rail-y {
        opacity: 0.6;
    }

    .ps__rail-x, .ps__rail-y {
        z-index: 1;
    }

    .step {
        position: relative;

        &.last {
            margin-right: 0 !important;
        }

        .number {
            width: 28px;
            height: 28px;
            background-color: #ffffff;
            border-radius: 50%;
            text-align: center;
            line-height: 28px;
            color: #979797;
            font-weight: bold;
            border: 1px solid #979797;
            cursor: pointer;

            &.flag {
                color: gray;
            }
        }

        &.current {
            .number {
                border: 1px solid #008A9C;
                color: #008A9C;
            }
        }

        &.active {
            .number {
                border: 1px solid #008A9C;
                background-color: #008A9C;
                color: #ffffff;
            }

            .flag {
                color: #fff !important;
            }
        }

        .arrow {
            color: #008A9C;
            position: absolute;

            &.top {
                transform: translate(50%, 0%) rotate(-90deg) scale(0.5);
                right: 50%;
                top: 0;
            }

            &.bottom {
                transform: translate(50%, 0%) rotate(90deg) scale(0.5);
                right: 50%;
                bottom: 0;
            }

            &.left {
                transform: translate(0%, -50%) rotate(180deg) scale(0.5);
                top: 50%;
                left: 0;
            }

            &.right {
                transform: translate(0%, -50%) scale(0.5);
                top: 50%;
                right: 0;

            }

            ::ng-deep path {
                fill: #008A9C;
            }
        }
    }

    &.layout-column.layout-align-start {
        padding-top: 25px;
    }

    &.layout-row.layout-align-start {
        padding-left: 25px;
    }

}

.video-js .vjs-overlay label {
    color: #666;
    text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}

#vjs_video_3 {
    width: 100%;
    height: auto;
}

.vjs-overlay {
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;

    button {
        z-index: 100;

        background-color: map_get($primary, 500);

        color: map_get($mat-white, 500);
        display: inline-block;
        font-size: 14px;
        line-height: 36px;
        border-radius: 1.5em;

    }

    &.vjs-overlay-top-left {
        &.vjs-overlay-background {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            align-items: center;
        }
    }
}

$app-user-action-button-height: 52px;
$app-user-action-padding-y: 24px;
$app-user-action-height: ($app-user-action-padding-y*2) + $app-user-action-button-height;

app-multi {

    .page-layout {

        // SINGLETONS -------------
        button {
            &.mat-fab {
                width: 50px;
                height: 50px;

                > .mat-button-wrapper {
                    width: 100%;
                    height: 100%;
                }

                .mat-icon { //
                    width: 100%;
                    height: 100%;
                    min-width: 100%;
                    min-height: 100%;
                    line-height: 100%;
                    // rattrape la marge interne de l'icone circulaire pour être au bord du bouton
                    transform: scale(1.2);
                }

                &.mat-accent {
                    background-color: transparent;

                    .mat-icon {
                        color: map_get($accent, 500);
                    }

                    &[disabled] .mat-icon path {
                        fill: none !important;
                    }
                }

            }

        }

        .multi-content {
            margin-top: 5px;
            margin-bottom: 5px;
            padding-right: 24px;
            padding-left: 24px;
        }

        .medias {
            padding: 16px;
        }

        app-image {
            .mat-card-content {
                background-color: map_get($primary, 500);
            }
        }

        // LAYOUTS -------------
        > .content > content {
            // border: 2px solid map_get($accent, 500);
            padding-bottom: $app-user-action-height; //
            padding-top: 2px;
            padding: 1px !important;

        }

        > .footer {
            position: relative;
            margin: 0 !important;

        }
    }
}


app-multiple-choice, app-multiple-choice-grid {
    .mainTable {
        border-collapse: collapse;
        display: table;
        width: 100%;
    }

    .rowHeaderTable {
        background-color: transparent;
    }

    .headerTable {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .headerCellTable {
        -webkit-box-shadow: none;
        box-shadow: none;
        display: table-cell;
        height: 2.5em;
        min-width: 48px;
        padding: .25em;
        text-align: center;
        vertical-align: middle;
        width: 120px;
    }

    .rowTable {
        display: table-row-group;
        background-color: mat.get-color-from-palette($primary, 50)
    }

    .spaceRow {
        background-color: #fff;
        height: 4px;
    }

    .radioGroupCell {
        display: contents;
    }

    .cellTable {
        display: table-cell;
        height: 2.5em;
        min-width: 48px;
        padding: .25em;
        text-align: center;
        vertical-align: middle;
        width: 120px;
    }
}

app-multiple-choice-grid {
    .mat-card-content {
        align-items: center;
    }
}

app-video-with-markers {
    min-width: 280px;

    .mat-dialog-container & { // dans la dialogue
        width: auto;
    }

    .mat-form-field-underline,
    .mat-form-field-ripple,
    .mat-focused .mat-form-field-ripple,
    .mat-form-field-underline,
    .mat-form-field-ripple,
    .mat-focused .mat-form-field-ripple,
    .mat-form-field.mat-focused .mat-form-field-ripple {
        background-color: map_get($accent, 500);
    }

}

app-video-editor {
    display: flex;
    flex: 1 1 100%;
    min-width: 0;

    .video-editor-import-actions {
        border: 1px solid map-get($accent, 500);
    }

    .mat-list {
        height: 124px !important;
        max-height: 124px !important;
        padding: 15px;
        overflow-x: auto;
        overflow-y: hidden;
        min-width: 0;

        border: 2px solid map_get($accent, 500);

        .mat-list-item {
            position: relative;
            height: 100%;

            &:last-child::before {
                content: ' ';
                height: 100%;
                display: block;
                width: 2px;
                background-color: map_get($accent, 500);
                position: absolute;
            }

            .mat-list-text > * {
                padding: 6px 0 0 0;
            }

            .mat-list-item-content {
                flex-direction: column-reverse;
                justify-content: center;
            }


            button.video-editor-btn-marker {
                &.mat-fab.mat-accent {
                    background-color: map_get($accent, 500);
                    //position: relative;
                    width: 42px;
                    height: 42px;
                    max-width: 42px;
                    max-height: 42px;
                    // color: $font-color;
                }

                .mat-icon {
                    path.svg-background {
                        display: none;
                    }

                    path.svg-main {
                        // stroke: map_get($mat-white, 500);
                    }
                }

            }
        }
    }

    .containerMarkers {

        .separator {
            border-left: 2px solid #EC184F;
        }
    }

}

app-video-editor, app-video {
    .mat-card.activities-card {
        padding: 0;

        .mat-card-content {
            width: 100%;
        }
    }

    app-video-with-markers {
        display: block;
        max-width: 100%;
        max-height: 100%;

        width: 40vh;
        @media screen and (min-height: 475px) and (max-height: 574px) {
            width: 65vh;
        }
        @media only screen and (min-height: 575px) and (max-height: 674px) and (orientation: portrait) {
            width: 100vw;
        }
        @media only screen and (min-height: 575px) and (max-height: 674px) and (orientation: landscape) {
            width: 115vh;
        }
        @media only screen and (min-height: 675px) and (orientation: portrait) {
            width: 100vw;
        }
        @media only screen and (min-height: 675px) and (orientation: landscape) {
            width: 100vh;
        }
        @media only screen and (min-height: 799px) and (max-width: 900px) and (orientation: landscape) {
            width: 110vh;
        }
        @media only screen and (min-height: 799px) and (min-width: 901px) and (orientation: landscape) {
            width: 100vh;
        }
    }

    .show-markers-list app-video-with-markers {
        width: 30vh;
        @media screen and (min-height: 475px) and (max-height: 574px) {
            width: 40vh;
        }
        @media screen and (min-height: 575px) and (max-height: 674px) {
            width: 75vh;
        }
        @media screen and (min-height: 675px) and (max-height: 774px) {
            width: 64vh;
        }
        @media screen and (min-height: 775px) and (max-height: 874px) {
            width: 79vh;
        }
        @media screen and (min-height: 875px) {
            width: 90vh;
        }
    }


}

app-video-markers {
    border: 2px solid mat.get-color-from-palette($primary);
    border-radius: 15px;
    overflow: hidden;

    form {
        &.play-mode {
            position: relative;
            padding-top: 90px;

            .question-field {
                position: absolute;
                top: 0;
                width: 100%;
            }

            > div:last-child {
                padding-top: 20px;
            }
        }

        &.editor-mode .question-field {
            padding-top: 20px;
        }

        &.note {
            textarea {
                height: 45vh;
            }
        }

        .mat-form-field-fusion .mat-form-field {
            margin-bottom: 0;
        }
    }

    a {
        word-wrap: break-word; // to constrain long url text to stay inside border
    }

}

app-interactive-image {
    /* ***************
    **module-css-plan // help to copy/paste on theme
        .clickable-target {}
        .target-area { // interactive viewport
            .target-details {
                .target-details__header {
                    .titleMedia {}
                    button.Close {}
                }
                .target-details__content {}
                .target-details__actions {}
            }
        }

     *************** */
    display: flex;
    flex: 1;

    .mat-card {
        color: map_get($mat-white, 500);
        flex-direction: column;

        .mat-card-content {
            flex-direction: column !important;
            box-sizing: border-box !important;
            display: flex !important;
            max-width: 100% !important;
            align-items: center !important;
            justify-content: center !important;

            app-consignes .wording {
                display: none;
            }
        }

        /*circle around search icon**/
        .clickable-target { // target interactive
            position: absolute;
            border-radius: 50%;
            cursor: pointer;
            width: 40px;
            height: 40px;
            padding: 3px;
            display: flex;
            justify-content: center;
            align-items: center;

            color: map_get($accent, 500);
            background: map_get($mat-white, 500);

            .mat-icon {
                height: 30px;
                width: 30px;
                color: map_get($accent, 500);

                svg {
                    height: 30px;
                    width: 30px;
                }
            }

            &.visited {
                background-color: map_get($accent, 500);

                .mat-icon {
                    color: map_get($mat-white, 500);
                }
            }

        }

        .target-area { // interactive viewport
            flex: 1;

            position: relative;
            max-width: 100%;
            max-height: 100%;
            text-align: center;

            min-width: 0;
            min-height: 100%;

            height: 100%;
            flex-direction: column;
            box-sizing: border-box;
            display: flex;
            place-content: center;
            align-items: center;

            background-color: map_get($accent, 500);

            img {
                height: 100%;
                width: auto;
                display: inline-block;
                min-width: 0;
                max-width: 100%;
                min-height: 100%;
                max-height: 100%;

                &.img-paysage {
                    height: auto;
                }

            }

            .target-details { // focus view
                //height: 90%;
                width: 100%;
                min-height: 0;
                display: flex;
                flex-direction: column;
                background-color: map_get($primary, 500);

                flex: 1;

                & + .target-details button.Close {
                    display: none;
                }

                &.wrapper-audio {
                    //height: auto;
                    //min-height: 0;
                    flex: 1 0 0%;
                    min-height: 140px;

                    .target-details__header {
                        min-height: 60px;
                    }
                }

                &.wrapper-image {
                    flex: 3 4 0%;
                }

                .target-details__header { // focus view header
                    display: flex;
                    align-items: center;
                    padding: 20px;

                    .title {
                        font: 800 20px/24px "Lato", "Helvetica Neue", sans-serif;
                    }

                    button.Close {
                        background-color: transparent;
                        margin-left: auto;

                        .mat-icon { // TODO propriétés à déporter vers les boutons
                            font-size: 32px;
                            width: 32px;
                            height: 32px;
                            min-width: 32px;
                            min-height: 32px;
                            line-height: 32px;

                            svg path {
                                fill: none;
                            }
                        }

                    }
                }

                .target-details__content {
                    min-width: 40vw;
                    min-height: 40vh;
                    height: 100%;
                    width: auto;
                    display: flex;
                    flex: 1;
                    justify-content: center;
                    align-items: center;
                    min-height: 0;

                    video, image, audio {
                        margin: 0 20px 20px 20px;
                    }

                    video, image {
                        max-height: 100%;
                    }
                }

                .target-details__actions {
                }
            }
        }
    }

    app-dialog & {
        .mat-card-footer, .mat-card-header {
            width: 100%;
        }
    }

}

app-summary {
    // fxFill
    height: 100%;
    margin: 0px;
    min-height: 100%;
    min-width: 100%;
    width: 100%;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;

    .qcmu {
        h2 {
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
        }

        .pastille {
            cursor: initial;
            font-weight: bold;
            font-size: 24px;
            width: 74px;
            height: 74px;
            border-radius: 50%;
            position: relative;

            mat-icon {
                position: absolute;
                right: -4px;
                top: -4px;
            }
        }
    }
}

app-tool {
    mat-card-content {
        align-items: center;

        mat-icon.mat-icon {
            width: 250px;
            height: 250px;
        }
    }
}

fuse-app-lessons-tab {
    .create-lesson-helper {
        font-size: 16px;
        padding-left: 16px;
    }
}

app-summary-activity {
    height: 100%;
}

editor-add-step {
    .step-type-content {
        flex-direction: column;
        box-sizing: border-box;
        display: flex;
        place-content: flex-start;
        align-items: flex-start;
        flex: 1 1 0;

        .step-type-wrapper {
            flex: 1 1 auto;
            box-sizing: border-box;
            flex-direction: row;
            display: flex;
            place-content: center flex-start;
            align-items: center;
            border: 2px solid;
            background-color: white;
            padding: 10px;

            .step-type {
                flex-direction: column;
                box-sizing: border-box;
                display: flex;
                max-width: 100%;
                place-content: center flex-start;
                align-items: center;

                cursor: pointer;
                padding: 10px;

                .mat-icon {
                }

                label {
                    text-align: center;
                }
            }
        }
    }
}

app-editor-activities-list {

    table {
        thead {
            display: none;
        }

        .mat-column-action {
            text-align: right;
        }
    }
}

app-feedback {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;


    > .content {

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;

        padding: 6px;
        margin-top: auto;

        .motivational-wrapper {
            border-radius: 10px;
            padding-left: 10px;
            padding-right: 10px;
            background-color: map-get($mat-fusedark, 100);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;


            &.earning {
                text-align: center;
                border-radius: 10px;
                color: white;
                background-color: map_get($primary, 500);
                padding: 5px;

                img {
                    margin: 10px 5px 5px;
                    width: 30%;
                }
            }

        }

    }

    .footer-action {
        display: flex;
        align-items: flex-start;
        padding: 6px;
        justify-content: center;

        button {
            border-radius: 5px !important;

        }
    }


}


.feedback-details-dialog,
.feedback-glossary-dialog,
.feedback-earning-dialog {

    mat-dialog-container.mat-dialog-container {
        .mat-dialog-title {
            background-color: map-get($primary, 500);
            color: white;

            button.close {
                border: 1px solid white;
            }
        }
    }


}

.feedback-earning-dialog {
    background-color: map-get($primary, 500);

    mat-dialog-container.mat-dialog-container {
        background-color: inherit;

        .mat-dialog-title {
            margin-bottom: 0;
        }
    }


    .mat-dialog-content > div { // div en trop
        height: 100%;
        flex-direction: column;
        box-sizing: border-box;
        display: flex;
        place-content: center flex-start;
        align-items: center;

        color: white;
        font-size: 24px;
    }

    .feedback-earning-dialog-content-header {
        margin-bottom: 35px;

        .image {
            height: 12vh;
            min-height: 120px;
            max-height: 200px;
        }

        .title {
            font-weight: 700;
        }

        p {
            margin-top: 0;
            margin-bottom: 0;
            font-weight: 700;
        }
    }

    .feedback-earning-dialog-content-inner {
        height: 100%;
        flex-direction: column;
        box-sizing: border-box;
        display: flex;
        place-content: stretch flex-start;
        align-items: stretch;
        max-width: 100%;
        text-align: center;

        .medal {
            height: 100%;
            flex-direction: row;
            box-sizing: border-box;
            display: flex;
            place-content: center space-between;

            img {
                height: 20vh;
                width: auto;
            }
        }

        .medals {
            height: 100%;
            flex-direction: row;
            box-sizing: border-box;
            display: flex;
            place-content: center space-between;
            align-items: center;
            padding-bottom: 20px;
            flex-wrap: wrap;

            img {
                height: 11vh;
            }
        }

        .stars {
            height: 20vh;
            width: auto;

            img {
                height: 100%;
            }
        }

        .stars-upper {
            padding: 10px;
        }

        p {
            margin-top: 0;
            margin-bottom: 0;
            font-weight: 700;
        }
    }

    .mat-dialog-actions {
        background-color: transparent;

        button.mat-raised-button.mat-accent {
            background-color: map-get($accent, 500) !important;
            color: map-get($mat-black, 500);
            margin-right: 0 !important;
        }
    }
}

app-medal {
    .mat-icon {
        width: 66px;
        height: 66px;
    }
}

app-star {

}

app-wins-summary {
    .title {
        color: white;
        padding: 6px 10px 6px 16px;
        min-height: 52px;
        margin: 0;

        .mat-icon {
            color: white;
            border: 1px solid white;
            border-radius: 25px;
        }

        button.close {
            box-shadow: none;
            width: 32px;
            height: 32px;
        }
    }

    h2 {
        font-family: Heebo;
        font-style: normal;
        font-size: 24px;
        letter-spacing: 0.5px;
        color: #FFFFFF;
        display: inline;
    }

    .flexContainer {
        display: flex;
        flex-direction: column;
    }

    .flexRow {
        display: inline-flex;
        justify-content: space-around;
        color: white;
    }

    .flexRowTitle {
        display: inline-flex;
        justify-content: space-around;
        color: white;
        font-family: Heebo;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;

        text-align: center;
        letter-spacing: 0.75px;
        text-transform: uppercase;

        .bronze {
            transform: translateX(-6px);
        }

        .argent {
            transform: translateX(-10px);
        }
    }
}


app-memory {
    mat-card-content {
        justify-content: space-around !important;
    }

    .answers-zone {
        display: flex;
        justify-content: space-between;
    }

    .answer-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        .image-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            min-height: 200px;
            max-height: 200px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }
}

app-memory-card-info {
    flex: 1;

    .answer-number {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .answer-text {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;

    }

    .icone-read {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    audio {
        display: none;
    }
}

app-memory-find-zone {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    button {
        height: auto;
        flex-basis: calc(25% - 2px) !important;
    }

    img {
        width: 100%;
        height: 100%;
    }
}

app-qcm app-consignes, app-qcu app-consignes {
    text-align: center;
}


app-dictionary {
    padding-top: 140px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;

    .container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        grid-gap: 5px;
        gap: 20px;

        app-flashcard {
            width: 45%;
        }

        .check {
            visibility: hidden;
        }

        app-flashcard {
            .overlay {
                span {
                    visibility: hidden;
                }
            }
        }

        app-flashcard.is-playing {
            .overlay {
                span {
                    visibility: visible;
                }
            }
        }

        app-flashcard.is-selected .overlay {
            background: transparent;
        }
    }
}



