app-animated-canvas {
    #player-container {
        width: 100%;
        height: 40vh;
    }

    mat-spinner {
        margin-top: 25%;
    }

    .mat-progress-spinner circle, .mat-spinner circle {
        stroke: #743f90;
    }

    > div {
        border: 4px solid #FED501;
        border-radius: 15px;
        box-sizing: border-box;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
    }
}

app-badge-type-list {
    margin-top: 20px;

    .mat-list-base {
        padding-top: 20px;
    }



    .mat-list-base .mat-list-item {
        height: auto;

        .mat-list-item-content {
            padding: 0;
        }
    }

    button {
        @extend %button-big;
        @extend %button-image;
        @extend %button-image-big-purple;
        @extend %button-image-title;
        padding-right: 40px !important;
        margin-top: -3px; // to adjust space without touching padding calculated by calc formule use for button
        @media screen and (min-width: 430px) and (max-width: 795px) {
            padding-right: 60px !important;
        }

        mat-icon {
            position: absolute;
            right: 5%;
            height: 32px !important;
            width: 32px !important;

            &.bigger {
                height: 37px !important;
                width: 37px !important;
                right: 4%;
            }
        }
    }
}

app-badge-type-gallery {
    margin-top: 41px;
    width: 100%;
    // div with class auto add by mat-grid list
     .mat-grid-tile {
         .mat-figure {
             display: flex;
             flex-direction: column;
             height: 100%;
             width: 100%;
             margin-top: 8px !important;
             justify-content: flex-start !important;
         }
     }

    .mat-grid-tile-content {
        display: inherit;
    }

    .badge-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 98%;
        height: 70%;
        border: 4px solid #FED501;
        border-radius: 15px;
        padding: 5px;
        background: #fff;
    }

    img {
        max-width: 100%;
        max-height: 100%;
        padding: 10px;
    }

    .badge-container.purple {
        border: 4px solid #743F90;
    }

    .badge-container.gray {
        border: 4px solid #F2F2F2;
    }

    .list-container {
        display: flex;
    }

    button {
        @extend %button-image;
        @extend %button-half;
        @extend %button-half-purple;
        position: relative;
        top: -17px;
        display: flex;
        width: 93%;
        padding: 0;
        &::before {
            width: 0;
        }

        .mat-button-wrapper {
            position: absolute;
            display: flex;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            .mat-icon {
                display: flex;
                align-items: center;
                svg {
                    margin-top: -2px;
                }
            }
        }
    }

    button.equipped {
        background: transparent !important;

        .mat-button-wrapper {
            position: absolute;
            top: 41%;
            left: 50%;
            width: calc( 100% / 3.15 );
            height: 90%;
            margin: auto;
            justify-content: center;
            align-items: center;
            padding-bottom: 0;
            transform: translate(-50%, -50%);
            min-width: 20px;
            min-height: 20px;
            overflow: visible;
            @extend %button-image-square-gold;
            // to remove logic coming with @extend
            &:active, &:hover {
                transition: transform 0s, opacity 0ms;
                transform: translate(-50%, -50%) scale(1);
                opacity: 1;
            }
        }

        mat-icon {
            color: white;
            width: 50%;
            height: 50%;
        }
    }

    button.not-equipped {

        span {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
        }

        mat-icon {
            margin-left: 10px !important;
        }
    }

    button.not-equipped-locked {
        @extend %button-half-grey-light;
        .mat-button-wrapper {
            color: #743F90;
            // not use like a button for moment reset effect
            &:active, &:hover {
                transition: transform 0s, opacity 0ms;
                transform: translate(-50%, -50%) scale(1);
                opacity: 1;
            }

            cursor: default;
        }
    }

}

.preview_badge_modal {
    background-color: transparent !important;

    mat-dialog-container {
        box-shadow: none;
        background-color: transparent !important;
        overflow: visible;
    }
}

app-preview-item-popup {

    .close {
        background-image: imageApp('buttons/btn-close-blue-dialog.png') !important;
        .mat-icon {
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
            line-height: 20px;
        }
    }

    .preview_content {
        position: relative;
        background-color: white;
        border-radius: 15px;
        padding-top: 0;

        .mat-dialog-title {
            padding: 0;
            min-height: 30px;
        }

        > div {
            margin: 5px -20px;
            // app animated container div must have a white bg
            &.white {
                background-color: white;
                border-radius: 15px;
            }
        }
    }

    button.action-button {
        width: 116%;
        margin-left: -8%;
        margin-right: 8%;
        justify-content: center;
        box-shadow: none !important;
        font-style: normal;
        font-weight: bold;
        font-size: 18px !important;
        line-height: 22px;
        margin-top: 15px;
        @extend %button-big;
        @extend %button-image;
        @extend %button-image-big-purple;

        .mat-button-wrapper {
            width: 100% !important;
            width: 97%;
            display: flex;
            justify-content: space-between;
            flex-direction: row;

            span {
                order: 0;
            }
        }

        .mat-icon-group {
            display: flex;
            align-items: center;
        }
    }

    button.equipped-button {
        background-color: transparent !important;
        width: 116%;
        margin-left: -8%;
        margin-right: 8%;
        justify-content: center;
        box-shadow: none !important;
        font-style: normal;
        font-weight: bold;
        font-size: 18px !important;
        line-height: 22px;
        margin-top: 15px;
        @extend %button-big;
        @extend %button-image;
        @extend %button-image-big-gold;
        @extend %button-image-title;
        cursor: default;
        // not use like a button for moment reset effect
        &:active, &:hover {
            transition: transform 0s, opacity 0ms !important;
            transform: scale(1) !important;
            opacity: 1 !important;
        }

        .mat-button-wrapper {
            width: 100% !important;
            width: 97%;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            line-height: 2em;

            span {
                order: 0;
            }
        }

        .mat-icon-group {
            display: flex;
            align-items: center;
        }
    }

    button.close_modal {
        top: -20px;
        @extend %button-image-close;
        box-shadow: none !important;

        .mat-icon {
            @extend %button-image-icon;
            width: 28px !important;
        }
    }
}
