html, body {
    background: white;
}

 navbar-vertical-style-2 {

  .nav .nav-item .nav-link .nav-link-icon, .nav .nav-item .nav-link .collapse-arrow {
  }

  .nav-link-title {
  }

  .nav .nav-item .nav-link.active {
  }

  .navbar-header {

    .logo {
      display: flex;
      align-items: center;
        .logo-text {
            display: none;
        }
    }

  }


  .nav {

    .nav-subheader {
      display: flex;
      align-items: center;
      height: 48px;
      font-weight: 500;
      padding-left: 24px;
      margin-top: 8px;
      font-size: 12px;
      white-space: nowrap;
    }

    .nav-group {
      display: block;

      > .group-title {
        position: relative;
        display: flex;
        align-items: center;
        height: 48px;
        font-weight: 500;
        padding-left: 24px;
        margin-top: 8px;
        font-size: 12px;
        white-space: nowrap;
      }
    }

    .nav-item {
      .nav-link {
        .nav-link-icon {
          margin-right: 16px;
        }

        .nav-link-icon {
          &:not(.svg-icon){
            // display: none;
          }
          &.svg-icon{
          }
        }
        .collapse-arrow {

        }


        > .nav-link-title {
          flex: 1;
          white-space: normal;          
        }
      }
    }
  }



}

