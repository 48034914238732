%cdk-overlay-1, CDK-OVERLAY-1 {
    padding: 0 10px 0 10px;
}

%mat-dialog-container, MAT-DIALOG-CONTAINER {
    overflow: initial;
    background: transparent;
    padding: 0 40px;
    box-shadow: none;

    &.mat-dialog-container.mat-dialog-container { // ? override bad default mindmap.scss property
        width: 100%;
        max-width: 454px;
        overflow: auto;
        padding-top: 27px;
        padding-bottom: 50px;
    }

    //@media(min-width:400px){
    //    min-width: 400px;
    //}
    // reset default
    border: 0;

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0;
    text-align: center;

}

%fuse-confirm-dialog, FUSE-CONFIRM-DIALOG {
    background-color: transparent;
}

%mat-dialog-title, MAT-DIALOG-TITLE {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 13px 13px 0 0;
    padding: 40px 30px 30px 30px;
    margin: 0;

    line-height: 20px;
    text-align: center;
    color: #743F90;

    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        margin: 0;
        position: relative;
        text-align: center;

        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0;
    }

    .close {
        z-index: 100;
        position: absolute;
        top: -20px;
        right: -20px;
        @extend %button-image-close;
    }

}

%mat-dialog-content, MAT-DIALOG-CONTENT {
    background-color: white;
    padding: 0 30px;
    min-width: auto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0;
    text-align: center;
    color: #743F90;

}

%mat-dialog-actions, MAT-DIALOG-ACTIONS {
    background-color: white;
    border-radius: 0 0 13px 13px;

    flex-direction: column !important;
    padding: 30px 30px 0 30px;

    button {
        width: calc(100% + 60px);
        display: block;
        position: relative;
        top: 0;
        margin-bottom: 0;
        // reset default
        border-radius: 0;
        background-color: transparent;
        box-shadow: none;

        @extend %buttonWideHeight;
        padding: 10px 30px 5% 30px;
        margin-left: -10px !important;
        margin-right: -10px !important;
        margin-bottom: 20px;

        .mat-button-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: auto;

            @extend %button-image;
            @extend %button-wide;
            @extend %button-image-wide-purple;

            padding: 6px 30px 4% 30px;

            justify-content: center;

            &::before {
                padding-top: calc(100% / 5);
            }
        }

        &.mr-16 {
            margin-right: 0 -30px !important;
        }

    }

    button.confirm {
        .mat-button-wrapper {
            @extend %button-image-wide-red;
        }
    }

}


mat-dialog-container.mat-dialog-container {
    @extend %mat-dialog-container;
    // fuse-confirm-dialog {
    @extend %fuse-confirm-dialog;

    .mat-dialog-title {
        @extend %mat-dialog-title;
    }

    .mat-dialog-content {
        @extend %mat-dialog-content;
    }

    .mat-dialog-actions {
        @extend %mat-dialog-actions;
    }

    // }

}

.cdk-overlay-pane {
    width: 100%;
    overflow: auto;

    .cdk-overlay-connected-position-bounding-box & {
        width: auto;
    }

    max-width: 545px !important;
    justify-content: center;
}

%cdk-overlay-1, CDK-OVERLAY-1 {
    padding: 0 10px 0 10px;
}

%mat-dialog-container, MAT-DIALOG-CONTAINER {
    overflow: initial;
    background: transparent;
    padding: 0 40px;
    box-shadow: none;

    &.mat-dialog-container.mat-dialog-container { // ? override bad default mindmap.scss property
        width: 100%;
        max-width: 545px;
    }

    //@media(min-width:400px){
    //    min-width: 400px;
    //}
    // reset default
    border: 0;
}

%mat-dialog-title, MAT-DIALOG-TITLE {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 13px 13px 0 0;
    padding: 40px 30px 30px 30px;
    margin: 0;

    line-height: 20px;
    text-align: center;
    color: #743F90;

    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        margin: 0;
        position: relative;
        text-align: center;

        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0;
    }

    .close {
        z-index: 100;
        position: absolute;
        top: -20px;
        right: -20px;
        @extend %button-image-close;
    }

}

%mat-dialog-content, MAT-DIALOG-CONTENT {
    background-color: white;
    padding: 2px 30px;
    min-width: auto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0;
    text-align: center;
    color: #743F90;

    .tts {
        cursor: pointer;
    }

}

%mat-dialog-actions, MAT-DIALOG-ACTIONS {
    background-color: white;
    border-radius: 0 0 13px 13px;

    flex-direction: column !important;
    padding: 30px 30px 0 30px;

    button {
        width: calc(100% + 40px);
        display: block;
        position: relative;
        top: 0;
        margin-bottom: 0;
        // reset default
        border-radius: 0;
        background-color: transparent;
        box-shadow: none;

        @extend %buttonWideHeight;
        padding: 10px 20px 5% 20px;
        margin-left: -20px !important;
        margin-right: -20px !important;
        margin-bottom: 20px;

        .mat-button-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: auto;

            @extend %button-image;
            @extend %button-wide;
            @extend %button-image-wide-purple;

            padding: 6px 30px 4% 30px;

            justify-content: center;

            &::before {
                padding-top: calc(100% / 5);
            }
        }

        &.mr-16 {
            margin-right: 0 -30px !important;
        }

    }

}


mat-dialog-container.mat-dialog-container {
    @extend %mat-dialog-container;
    // fuse-confirm-dialog {
    @extend %fuse-confirm-dialog;

    .mat-dialog-title {
        @extend %mat-dialog-title;
    }

    .mat-dialog-content {
        @extend %mat-dialog-content;
    }

    .mat-dialog-actions {
        @extend %mat-dialog-actions;
    }

    // }

}

.cdk-overlay-pane {
    max-width: 100% !important;
}

.question_set_modal {
    mat-dialog-container.mat-dialog-container {
        .mat-dialog-title {
            min-height: auto;
            padding-bottom: 0;
            justify-content: flex-start;

            h2 {
                text-transform: none !important;
                font-size: 14px;
                line-height: 17px;
                color: #005D92;
            }

            .close {
                background-image: imageApp('/buttons/btn-close-blue-dialog.png') !important;

                .mat-icon {
                    width: 20px;
                    height: 20px;
                    min-width: 20px;
                    min-height: 20px;
                    line-height: 20px;
                }
            }

            .assignment {
                position: relative;
                padding-left: 20px;
                display: flex;
                justify-content: flex-start;
                text-align: left;

                &::before {
                    display: inline-block;
                    background: #FF6862;
                    width: 9px;
                    height: 9px;
                    border-radius: 9px;
                    content: '';
                    margin-right: 6px;
                    position: absolute;
                    left: 0;
                    top: 3px;
                }
            }
        }

        .mat-dialog-content {
            display: flex;
            flex-direction: row;
            align-items: center;

            mat-progress-bar {
                height: 10px !important;
                border-radius: 10px;
                margin: auto;
                border: 1px solid rgba(15, 167, 255, 0.2);

                .mat-progress-bar-buffer {
                    box-shadow: inset 0 4px 3px rgba(0, 0, 0, 0.12);
                    background: #e0f9ff;
                }
            }

            .mat-progress-bar .mat-progress-bar-fill::after {
                border-radius: 13px;
            }

            .progress-number {
                white-space: nowrap;
                margin-left: 15px;
                color: #005D92;
            }
        }

        .mat-dialog-actions {
            display: block;
            padding-top: 30px;
            padding-bottom: 20px;

            > * {
                margin-left: -30px;
                margin-right: -30px;
            }
        }
    }
}

.mat-menu-panel {
    .mat-menu-item {
        font: 700 12px/16px #{$font-family};

        .mat-icon {
            display: none;
        }
    }
}

.tts-reading {
    color: #0fa9ff !important;
}
