
app-text-matching {
    display: flex;
    @extend %activities-card;

    .mat-card.mat-card {
        .mat-card-header {
            flex: 1 1 50%;
        }
    }

    mat-card-content.mat-card-content.vertical,
    mat-card-content.mat-card-content {
        align-items: center;

        .guessing-item {
            color: #005D92;
            width: 100%;

            &.instruction-as-picture {
                background: none;
                justify-self: self-start;
                padding: 0;
                flex: 0;
            }
        }


        button {
            @extend %button-image;
            @extend %button-half;
            @extend %button-half-blue;
            border: none;
            margin: 0 0 8px 0 !important;
            justify-content: center;
            padding: 0;

            .mat-button-wrapper {
                position: relative;
                align-self: stretch;
                > span {
                    position: absolute;
                    top: 40%;
                    transform: translateY(-50%);
                }
            }
            &::before {
                padding-top: calc(100% / 2.58);
            }


            &[disabled="true"], &.mat-raised-button[disabled][disabled] {
                // color: rgba(255, 255, 255, 1);
                color: white;
            }

            &.pristine { // perfect
                //@extend %button-half-green;
            }

            &.was-correct {
                opacity: 0.3;
                cursor: not-allowed;
            }

            &.incorrect {
                @extend %button-half-grey;
                color: white;
            }

            &.currently-correct {
                @extend %button-half-green;
                color: white;
            }
        }

        .available-answers.input-as-picture {
            margin-top: 0;
            padding-top: 30px;
            column-gap: 2%;
            justify-content: center;
            max-width: 50vh;
        }

        .available-answers.input-as-picture button.button-image {
            flex: 0 1 49%;
            flex-wrap: wrap;
            height: auto;
            border: 4px solid #0FA9FF;
            border-radius: 10px;
            padding: 0 !important;
            box-sizing: border-box;
            margin-bottom: 12px;
            box-shadow: 0 3px 20px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
            background: transparent !important;
            position: relative;

            .mat-button-wrapper > span {
                position: relative;
                top: inherit;
                transform: inherit;
            }

            &:active {
                transition: none !important;
                transform: none !important;
            }

            &::before {
                display: none;
            }

            img {
                width: auto;
                height: 100%;
                border-radius: 4px;
            }

            &.currently-correct {
                border: 4px solid #89E30C;
            }

            &.was-correct {
                border: 4px solid #89E30C;
                cursor: not-allowed;
            }

            &.incorrect {
                border: 4px solid #3A3A3A;
            }
        }


        .available-answers {
            flex: 1;
            align-items: flex-start;
            align-content: flex-start;

            &.input-as-text {
                justify-content: center;
                flex: 1;
                margin-top: 0;
                display: flex;
                align-content: center !important;
                padding-bottom: 40px;

            }

            &.columns-1 {
                //margin: 0 -10px;
                //width: calc( 100% + 20px );
                align-items: center;
                align-content: center;

                justify-content: center;
                flex-direction: row;

                button {
                    width: 50%;
                }
            }


        }
    }

}
