@use '~@angular/material' as mat;
$mat-success: mat.get-color-from-palette(mat.$green-palette, 500);

 .mat-icon, .icon {
  //color: rgba(0, 0, 0, 1); // TODO vérifier si nécessaire
}

 .mat-success {
  color: $mat-success;
}
