@use '~@angular/material' as mat;
.count-entities {
    font: $form-field-input-font;
}

.filters {

    @extend %mat-form-field-label-on-top;

    .mat-form-field.mat-form-field-should-float,
    .mat-form-field.mat-form-field-can-float,
    .mat-form-field.mat-form-field-appearance-legacy .mat-form-field-wrapper {
        min-width: 150px;
        margin-right: 10px;
        &.field-school {
            min-width: 190px;
        }
    }

    button.mat-raised-button {
        border-radius: 1.5em;
        .count {
            margin-right: -13px;
            padding: 0 5px;
        }
    }

    .mat-toolbar-row, .mat-toolbar-single-row {
        font-family: '#{$font-family};';
    }

    .btn-collapse {
        color: $default-font-color;


        span{
        }
        .mat-icon {
            @include sizeFix(32px);
            border: 1px solid $default-font-color;
        }
        &:not(.mat-stroked-button) {
            color: $default-font-color;
        }
    }

    // TODO remove :not selector :(
     button:not(.mat-stroked-button) svg.mat-datepicker-toggle-default-icon > path:first-child {
        fill: mat.get-color-from-palette($mat-white, 500);
    }

}


