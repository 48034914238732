table {
    padding: 0 8px;
    width: 100%;

    .mat-icon {
        color: $table-icon-stroke-color;
        height: 32px;
        width: 32px;
        [svgIcon="submenu"] {

        }
    }

    th.cdk-column-consulted, td.cdk-column-consulted {
        width : 150px;
        text-align: center;
        .mat-icon {
            color: $table-icon-stroke-inactive-color;
            height: 28px;
            width: 28px;

            &.active {
                color: $table-icon-stroke-color;
            }
        }
    }

    &.mat-table {
        border-collapse: separate;
        border-spacing: 0 7px !important;
    }

    th.mat-header-cell, td.mat-cell {
        border: none;
    }
    td.mat-cell {
        padding: 0px 8px;
    }

    th,
    th.mat-header-cell
    {
        color: $table-thead-font-color;
        border-top: 1px solid rgba(151, 151, 151, 0.5);
        border-bottom: 1px solid rgba(151, 151, 151, 0.5);
        &:first-child {
            border-left: $table-thead-border;
            border-top-left-radius: $table-thead-border-radius;
            border-bottom-left-radius: $table-thead-border-radius;
        }

        &:last-child {
            border-right: 1px solid rgba(151, 151, 151, 0.5);
            border-top-right-radius: $table-thead-border-radius;
            border-bottom-right-radius: $table-thead-border-radius;
        }
    }

    td {

        &:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }

    tr,
    tr.mat-row {
        height: 56px;
        box-shadow: 0px 1px 10px rgba(0, 175, 236, 0.4);
        border-radius: 10px;

        &.mat-header-row {
            height: 34px;
        }
    }

    thead, .mat-header-row {
        background-color: $table-thead-background-color;
        box-shadow: none;

        .mat-checkbox-frame {
            border-color: $table-thead-checkbox-color;
        }
        tr {
            border-radius: $table-thead-border-radius;
        }
    }

    tbody {
        &:before {
            content: "";
            display: block;
            height: 2px;
        }

        tr:hover {
            box-shadow: 0px 1px 10px rgba(0, 175, 236, 0.4);
            .nav-link-title {
                color: $table-icon-stroke-color-hover;
            }
        }
    }
}
