@use '~@angular/material' as mat;
.mat-form-field-ripple {
    background-color: mat.get-color-from-palette($accent);
}

mat-sidenav-container.dynamic-sidenav-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    mat-sidenav-content {
        position: relative
    }
}

app-main-menu {
    .mat-list-base .index-1 .mat-list-item .mat-list-item-content,
    .mat-list-base .index-1 .mat-list-option .mat-list-item-content {
        padding-left: 24px;
    }

    .mat-list-base .index-2 .mat-list-item .mat-list-item-content,
    .mat-list-base .index-2 .mat-list-option .mat-list-item-content {
        padding-left: 32px;
    }
}