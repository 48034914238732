body.short-answer content, body.short-answer .outlet-player-navigation {
    height: auto !important;
}

app-short-answer {
    display: flex;
    @extend %activities-card;
    max-height: 500px;

    .mat-card {
        mat-card-content.mat-card-content {
            app-consignes.centeredContent {
                flex-direction: column;

                .is-instruction {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;

                    .instruction {
                        padding: 20px 20px 10px;
                    }
                }
            }

            .container .wording {
                padding-bottom: 0;

                img {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }


            .latex-wrapper {
                margin-top: 0;
                border-top-left-radius: 0;
                border-top-right-radius: 0;

                .katex {
                    font-size: 18px;
                    letter-spacing: -0.06em;
                }

                &.correction .renderedInputContent {
                    //padding-left: 14px;
                    //padding-right: 14px;
                }

                &.correction .base, .incorrect .base, .incorrect.base {
                    color: #3A3A3A;
                }

                &.missingResponse .base {
                    color: #FFA615;
                }

                &.right .base, .currently-correct .base, .currently-correct.base {
                    color: #89E30C;
                }

                &.wrong .base, .wrong .base, .wrong.base {
                    color: #FF6862;
                }

                &.validated .base {
                    color: #17D0AD;
                }
            }


            &.instruction-is-picture {

                .container .wording {
                    padding-bottom: 0;

                    img {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }


                .latex-wrapper {
                    margin-top: 0;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    padding: 10px 20px 10px 20px !important;

                    &.correction .renderedInputContent {
                        //padding-left: 14px;
                        //padding-right: 14px;
                    }

                    &.correction .base, .incorrect .base, .incorrect.base {
                        color: #3A3A3A;
                    }

                    &.missingResponse .base {
                        color: #FFA615;
                    }

                    &.right .base, .currently-correct .base, .currently-correct.base {
                        color: #89E30C;
                    }

                    &.wrong .base, .wrong .base, .wrong.base {
                        color: #FF6862;
                    }

                    &.validated .base {
                        color: #17D0AD;
                    }
                }
            }

            .latex-wrapper {
                @extend %textCartouche;
                background-color: rgba(15, 169, 255, 0.2);
                border: none;
                margin: 0;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                width: 100%;
                overflow: hidden;

                &.is-picture {
                    background: transparent !important;
                }

                app-keyboard-latex-field {
                    span {
                        font-family: "drBuch";
                    }
                }
            }
        }

        app-consignes {
            &.centeredContent {
                .instruction {
                    p {
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
    }
}

