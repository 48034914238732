// common styles

%fontbold{
    font-family:'nexabold';
    font-size: 14px;
}
%list_style{
    list-style: none;
    padding: 0px;
}
%white_bg{
    background-color:#ffffff;
}
.fuse-card-header {
    background-color: #f4f4f4;
}

.header{
    position:relative;

    .heading{
        position: absolute;
        top: 0;
        left: 20px;
        color: #979797;
        background-color: #f4f4f4;
        margin: 0;
        padding: 20px 40px;
        font-family:'nexabold';
        font-size: 18px;
        line-height: 23px;
    }
}
button:hover{
    cursor: pointer;
}
a{
    color:inherit;
}



/****** Custom css for section left panel mat-drawer********/
#section {
    position: relative;

    .section-wrap {
        position: relative;

        .close-button-icon {
            position: absolute;
            right: 10px;
            top: 5px;
            z-index: 9999;
            background: #fff;
        }
    }

    .mat-tab-group {

        border-top-left-radius: 10px 10px;
        border-top-right-radius: 10px 10px;
        border: 1px solid #FF787D;

        .mat-tab-header {
            background: #FFE4E5;
            border-top-left-radius: 10px 10px;
            border-top-right-radius: 10px 10px;
            border-bottom: none;
        }
    }

    .section-detail-content {
        .custom-textarea {
            min-height: 100px;
        }

        .section-separator {
            div {
                height: 2px;
                background: red;
            }

            span {
                color: #FF787D;
            }
        }

        .recall-section {
            background: #FEF5F5;
        }

        .sub-section {
            min-height: 110px;
            border-top: 1px solid red;

            mat-toolbar {
                border-top-left-radius: 0px 0px;
                border-top-right-radius: 0px 0px;
                background: #DFB900;

                mat-toolbar-row {
                    mat-icon {
                        width: 40px;
                        height: 48px;

                        svg {
                            path {
                                fill: #fff;
                            }
                        }
                    }

                    span {
                        &.title {
                            font-size: 12px;
                            color: #fff;
                        }
                    }
                }
            }

            .close-button-icon {
                position: absolute;
                top: -16px;
                right: -16px;
                background: #FFFFFF;
                -webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
                box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
                width: 32px;
                height: 32px;
                line-height: 32px;
            }

            .section-media-item {
                position: relative;
                cursor: pointer;
            }
        }

        .comment-student-view {
            border-top: 1px solid #FF787D;
            overflow-y: hidden;

            .listComments {
                overflow-y: auto;

                .fontBold {
                    font-weight: bold;
                }
            }

            .chat_happy {
                color: #00E998;

                svg {
                    path {
                        fill: #00E998;
                    }
                }
            }

            .chat_sad {
                color: #DFB900;

                svg {
                    path {
                        fill: #DFB900;
                    }
                }
            }

            .hourglass {
                color: #FF787D;

                svg {
                    path {
                        fill: #FF787D;
                    }
                }
            }

            .chat_close {
                color: #4A4A4A;

                svg {
                    path {
                        fill: #4A4A4A;
                    }
                }
            }

        }
    }


    fuse-section-comment-trainer {
        position: absolute;
        z-index: 9999;
        background: rgba(0, 0, 0, 0.6);

        form {
            min-width: 40%;
            min-height: 500px;
            background: #FFFFFF;
            border: 1px solid #DFB900;
            box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
            border-radius: 20px;

            mat-toolbar-row {
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                background: rgba(223, 185, 0, 0.1);

                span {
                    &.title {
                        color: #DFB900;
                        font-size: 14px;
                    }
                }

                button {
                    background: #FFFFFF;
                    -webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
                    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
                }
            }

            label {
                line-height: 25px;
                font-size: 14px;
                font-weight: bold;
            }

            mat-form-field {
                border: 1px solid #DFB900;

                textarea {
                    background: #fff;
                    min-height: 200px;
                }
            }

            .radio-button-section {
                background: #FFFFFF;

                box-sizing: border-box;
                border-radius: 14px;
                text-align: center;

                font-family: Nexa Light;
                line-height: normal;
                font-size: 14px;
                letter-spacing: 0.145455px;

                &.validated {
                    color: #00E998;
                    border: 1px solid #00E998;

                    mat-icon {
                        svg {
                            path {
                                fill: #00E998;
                            }
                        }
                    }
                }

                &.correct {
                    color: #DFB900;
                    border: 1px solid #DFB900;

                    mat-icon {
                        svg {
                            path {
                                fill: #DFB900;
                            }
                        }
                    }
                }

                &.closed {
                    color: #4A4A4A;
                    border: 1px solid #4A4A4A;

                    mat-icon {
                        svg {
                            path {
                                fill: #4A4A4A;
                            }
                        }
                    }
                }
            }
        }
    }
}

.section-details {
    .submit-button {
        background: #DFB900;
        box-shadow: 0px 1px 8px rgba(209, 209, 209, 0.5);
        border-radius: 18px;
        color: #fff;
    }
}