@import "../variables";

.ubolino navbar {

    &.navbar-background-color {
        background: $navbar-content-background-color;
    }

}


navbar navbar-vertical-style-2 {

    .mat-icon {
        color: $nav-icon-color;
    }

    div.navbar-header {
        background-color: #{$navbar-header-background-color};
        box-shadow: #{$navbar-header-box-shadow};

        .folded:not(.unfolded) & {
            margin-bottom: 65px;
            position: relative;
        }

        .logo {
            .folded:not(.unfolded) & {
                position: absolute;
                top: 65px;
                left: 0;
            }
        }
    }

    mat-icon {
        &.logo-icon-svg {
            color: $logo-icon-svg-color;
            width: $logo-icon-svg-width;
            height: $logo-icon-svg-height;

            .folded:not(.unfolded) & { // mini sidebar // = .folded [...] mat-icon.logo-icon-svg
                width: 100%;
                min-width: 40px;
                max-width: 152px;
                height: 55px;

                .brand-text {
                    opacity: 0;
                    transition-property: all;
                    transition-duration: 100ms;
                    transition-timing-function: ease;
                }

                .brand-background, .brand-text-ia {
                    transform: translate(-327px, -58px) scale(2);
                    transition-property: all;
                    transition-duration: 200ms;
                    transition-timing-function: ease;
                }
            }

            &[svgicon="menu"] {
                width: $navbar-header-icon-dimensions;
                height: $navbar-header-icon-dimensions;
                color: $navbar-header-icon-svg-color;
            }
        }

    }


    .nav .nav-item {
        .nav-link {
            .nav-link-title {
                color: $nav-link-title-color;
            }

            .nav-link-icon,
            .collapsable-arrow {
                font-size: $nav-link-icon-font-size;
                width: $nav-link-icon-width;
                height: $nav-link-icon-height;
                min-width: $nav-link-icon-width;
                min-height: $nav-link-icon-height;
                line-height: $nav-link-icon-line-height;
            }

            &.active {
                background: $gradient-ubolino-blue-horizontal;
                background-color: transparent !important;

                .nav-link-title {
                    color: $nav-link-title-color-active;
                }

                .nav-link-icon,
                .collapsable-arrow {
                    color: $nav-link-icon-color-active;
                }
            }

            &:hover {
                background: linear-gradient(90deg, #006182 0%, rgba(255, 255, 255, 0) 100%);

                .nav-link-title {
                    color: $nav-link-icon-color-hover;
                }

                .nav-link-icon, .collapsable-arrow {
                    opacity: 1;
                    color: $nav-link-icon-color-active;

                    svg {
                        color: $nav-link-icon-color-active;
                    }
                }
            }
        }

        &.nav-collapsable.open {

            .nav-link:first-child {
                .nav-link-icon, .collapsable-arrow {
                    opacity: 1;
                    color: $nav-link-icon-color-active;

                    svg {
                        color: $nav-link-icon-color-active;
                    }
                }
            }


        }


    }


    .nav > .navbar-content {
        display: flex;
        flex-direction: column;
        padding-bottom: 50px;
        > fuse-nav-vertical-item.nav-item {
            width: 100%;
        }
        > .nav-item:nth-last-child(3) {
            margin-top: auto;
        }
        > .nav-item:nth-last-child(3),
        > .nav-item:nth-last-child(2),
        > .nav-item:nth-last-child(1){
            .nav-link-icon {
                font-size: 18px;
                width: 18px;
                height: 18px;
                min-width: 18px;
                min-height: 18px;
                line-height: 18px;
            }
            .nav-link-title {
                font-size: 1rem;
            }
            .nav-link {
                height: 24px;
            }

        }
    }

    .menu-link {
        position: absolute;
        bottom: 133px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: currentColor;
        width: 100%;
        height: 48px;
        margin: 0;
        cursor: pointer;
        opacity: 0.7;
        text-decoration: none !important;
        user-select: none;

        .nav-link-title {
            flex: 1;
            width: 100%;
            padding: 0 24px 0 59px;
            color: #00afec;
            white-space: normal;
            text-decoration: none !important;
            &::before {
                position: absolute;
                left: 22px;
                content: '';
                display: block;
                width: 26px;
                height: 26px;
                min-width: 26px;
                min-height: 26px;
                font-size: 26px;
                line-height: 26px;
                background: url('/assets/ubolino_app/icons/contact.svg') no-repeat center center;
                background-size: 25px 25px;
            }
        }
        &:hover {
            background: linear-gradient(90deg, #006182 0%, rgba(255, 255, 255, 0) 100%);
            .nav-link-title {
                color: #4dc7f2;
                &::before {
                    background: url('/assets/ubolino_app/icons/contact_white.svg') no-repeat center center;
                    background-size: 25px 25px;
                }
            }

        }
    }

    .menu-link-block {
        position: relative;
    }

}

