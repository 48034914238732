@use '~@angular/material' as mat;
app-progress-bar-generic {
    position: absolute;
    bottom: 0%;
    background-color: mat.get-color-from-palette($primary);
    width: 100%;
    height: 3vh;

    .progress-bar {
        height: 3vh;
        background: linear-gradient(90deg, map_get($primary, 200) 97%, transparent);
    }

    .circle {
        position: absolute;
        bottom: 0%;
        left: -3vh;
        width: 3vh;
        height: 3vh;
        border-radius: 3vh;
        background: white;
        transform: translate(-3vh, 0px);
    }
}