#app-feedback {
    form {
        display: flex;
        flex-direction: column;
        margin: auto;
        background-color: white;
        padding: 30px;
        height: 92vh;
        width: 70vh;

        img {
            height: 15vh;
            margin: auto;
        }

        textarea {
            height: 25vh;
            margin-bottom: 20px;
        }

        button.mat-raised-button {
            margin: auto;
            height: 5vh;
        }
    }

}

fuse-register {
    /**
    to show info message at bottom of field
     */
    .info-mail {
        position: absolute;
        margin: auto;
        display: block;
        transform: translate(-14px, 18px);
        font-size: 12px;
    }

    #register-form .field-youAre {
        display: none;
    }

    fuse-mail-confirm {
        flex-direction: column;
        box-sizing: border-box;
        flex: 1;
        display: flex;

        #mail-confirm {
            display: flex;
            flex: 1;
            padding: 0;

            #mail-confirm-form-wrapper {
                justify-content: flex-start;
                padding: 0 !important;

                #mail-confirm-form {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    padding: 0 !important;

                    .logo {
                        flex: 0 0 53px;
                        padding: 0;
                        background-size: 120px auto;
                        background-position: bottom center;
                        background-image: icon('brand.svg');
                        background-repeat: no-repeat;

                        .mat-icon {
                            display: none;
                        }
                    }

                    .title {
                        flex: 0;
                        margin: 0 !important;
                        padding: 8px 0 30px 0;
                    }

                    .subtitle {
                        margin: 0 !important;
                        max-width: inherit !important;
                        flex: 0;
                        background-color: #fff !important;
                        word-break: break-all;
                        padding: 14px 24px;
                        border-radius: 10px;
                        border: 1px solid #0000001a;
                        color: #743F90 !important;
                        /* font-family: DR Buch; */
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 17px;
                        letter-spacing: 0;
                        text-align: center;
                    }

                    .message {
                        flex: 1;
                        margin: 0 !important;
                        padding-top: 40px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .text {
                            color: #743F90 !important;
                        }

                        .link {
                            color: #17D0AD !important;
                        }

                    }

                    .action-button {
                        flex: 0 0 80px;

                        button {
                            padding: 0;
                            margin: 0 auto;
                            background-color: transparent;
                            box-shadow: none;
                            line-height: 1.2em !important;
                            width: 200px;

                            .mat-button-wrapper {
                                @extend %button-image;
                                @extend %button-half;
                                @extend %button-half-green-up;
                                padding-top: 5px;
                                padding-bottom: 15px;
                                justify-content: center;
                                white-space: pre-wrap;
                            }
                        }

                    }
                }
            }
        }
    }

}

app-profile-delete-confirm-modal {
    .mat-dialog-actions {
        button {
            span {
                font-size: 18px !important;
            }
        }
    }
}

