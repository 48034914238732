@use '~@angular/material' as mat;

content.inner-scroll > :not(router-outlet) {
    min-width: 0;
    min-height: 0;
}
 .page-layout.simple.fullwidth > .content {
    height: 100%;
}
 content { //Safari
    flex-direction: column;
}

.touch-scroll { // patch
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.id-groups-form-dialog .dialog-content-wrapper {

    .mat-dialog-actions button {
        background-color: mat.get-color-from-palette($primary);
    }

}

.groups-inline-modal {
    .add {
        background-color: mat.get-color-from-palette($primary); // mv theme

    }
}