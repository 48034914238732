 .dl-listitem-inline, dl {

    hr {
        display: none;
    }
    dt, dd {
        display: inline;
    }
    dt {
        &::before {
            content: "";
            display: inline-block;
        }
        &::after {
            content: " : ";
            display: inline;
            padding-right: 5px;
            margin-left: 5px;
            font-weight: bold;
        }
    }
    dd {
        margin: 0 0 18px 0;
        p {
            display: inline;
            margin-top: 0;
        }
        &::after {
            content: "";
            display: block;
            height: 5px; // Space between item
        }

        &.span-divider {
            span:not(:last-of-type):after {
                content: ", ";
            }
        }

    }
}
 ul {
    &.ul-reset {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
}
