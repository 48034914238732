@use '~@angular/material' as mat;
.mat-column-nickname, .mat-column-pseudo, .mat-column-username {
    max-width: 160px;
}


app-group {

    .mat-column-code p {
        background-color: mat.get-color-from-palette($primary);
        display: inline-block;
        margin: 0;
        padding: 8px 27px;
        color: map_get($mat-white, 500);
        border-radius: 1.5em;
    }

}

app-users-import {
    mat-form-field {
        width: fit-content;
        min-width: 100%;

        .mat-form-field-wrapper {
            padding-bottom: 11px;
        }

        .mat-form-field-label-wrapper {
            overflow: inherit;
        }
    }
}

app-graph-group-management {
    .selected-exercice {
        color: map_get(mat.$grey-palette, 500);
        font-family: $typography-font-name2;
    }
    .statistics-button {
        @extend %mat-stroked-button-primary;
        margin-left: auto !important;
    }

}

fuse-groups-list {
    > .page-layout {
        max-height: 100%;
        >.content {
            flex: unset !important;
            tr & {
                flex: none;
            }
        }
    }
}


.id-groups-form-dialog {
    .dialog-content-wrapper .mat-dialog-content form .mat-form-field-label {
        font: 500 14px/16px #{$font-family}; // mv theme
        color: map_get($md-bluedark, 900); // mv theme
    }
    .mat-chip.mat-standard-chip .mat-icon {
        background-color: transparent;
        color: white;
        opacity: 1;
    }
    .dialog-content-wrapper {
        .mat-dialog-actions {
            min-width: 66px;
        }
    }
}


#groups-list-table .groups-inline-modal-learner , .groups-inline-modal {
    .add {
        background-color: map_get($mat-fusedark, 500) !important; // mv theme
    }
    .mat-chip.mat-standard-chip.mat-chip-disabled {
        opacity: 1;
    }

}
.groups-inline-modal {
    padding: 0 4px;
    tr & { // in component in table
        padding: 0;
    }
    .save-button[disabled] .mat-icon svg path { // TODO remove default comportment with prefix for fill icons and stroke icons
        fill: none;
    }
}


