@use '~@angular/material' as mat;
$color-header: $white-c;

app-chapters-list, app-assignments-list {
    mat-card {
        border-radius: 15px !important;
        margin: 20px 0;

        .mat-card-header-text {
            margin: 0 !important;

            .mat-card-title.assignment::before {
                display: inline-block;
                background: #FF6862;
                width: 9px;
                height: 9px;
                border-radius: 9px;
                content: '';
                margin-right: 6px;
            }

            mat-card-title {
                font-size: 14px !important;
            }
        }
    }
}

.mat-card.mat-card {
    display: flex;
    flex-direction: column;

    .mat-card-header {
    }

    .card-split-media-info & {
        min-height: 278px;
        background-color: map-get($mat-white, 500);


        mat-card-header {
            background-color: $secondary-c;
            z-index: 10;

            .mat-card-title * {
                font-size: 20px;
                font-weight: 700 !important;
                margin: 3px 0;
            }

            .pastille {
                display: none;
            }

            mat-icon {
                path {
                    fill: white;
                }
            }
        }

        .mat-card-image {
            width: 226px;
            min-width: 226px;
            max-width: 226px !important;
            height: auto;
            top: 0;
            z-index: 0;
            padding-top: 52px;
        }

        .mat-card-content {
            min-height: 100px;
            font: 700 12px/16px  #{$typography-font-name}, "Roboto", sans-serif;
            color: $secondary-c;
            margin-left: 226px;
            flex: 1;
            padding: 35px 18px 0;

            dd {
                font-weight: 400;
                margin: 0;

                &:before {
                    content: ' ';
                    width: 1px;
                    display: block;
                    height: 1em;
                    float: left;
                }
            }

            .get-date-label dt {
                display: block;
            }

        }

        .mat-card-actions {
            margin-left: 226px;

            button {
                font-size: 32px;
                width: 32px;
                height: 32px;
                min-width: 32px;
                min-height: 32px;
                line-height: 32px;
                box-shadow: none;

                .mat-icon {
                    color: $secondary-c;
                }

                &.button-download-theme {
                    margin-right: auto;
                }

                &.button-play {
                    background: mat.get-color-from-palette($primary);

                    &:before {
                        content: '';
                        display: block;
                        width: 32px;
                        height: 32px;
                        background: url(/assets/ubolino/icons/file-text.svg) no-repeat;
                        background-size: 18px 18px;
                        position: absolute;
                        background-position: 50% 50%;
                        transform: translate(0, -50%);
                        color: white;
                    }

                    .mat-icon {
                        display: none;
                    }
                }
            }
        }
    }
}

app-activities-home {
    app-assignments-list, app-chapters-list, app-concept-card, app-lesson-card, .lesson-card {
        .mat-card-actions {
            // min-height: 50px;

            min-height: 0;
            padding: 0;
            border: 0;
            margin: 0;
            padding-bottom: calc(100% / 25);

            button {
                box-shadow: none !important;
                width: 100%;
                display: block;
                position: relative;
                top: 0;

                .mat-button-wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    width: auto;
                    padding-bottom: 9px;

                    @extend %button-image;
                    @extend %button-medium;
                    @extend %button-image-medium-orange;

                    .mat-icon {
                        @extend %button-image-icon;
                    }

                    span {
                        @extend %button-image-title;
                    }
                }
            }
        }
    }
}

app-chapters-list {
    .mat-card.mat-card {
        padding: 16px 16px 8px 16px !important; //test ecart
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-sizing: border-box;
        box-shadow: 0 9px 10px rgba(0, 0, 0, 0.0560948), 0 3px 3px rgba(0, 0, 0, 0.0297004), 0 1px 2px rgba(0, 0, 0, 0.0154939), 0 0 1px rgba(0, 0, 0, 0.00577361);
    }

    .mat-card-actions {
        padding: 0 !important; // test ecart
        min-height: 0;
        border: 0;
        margin: 0;
        padding-bottom: calc(100% / 8.2) !important;
    }

    mat-card-title, ul, .assign_comment {
        color: #005D92;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;

        li {
            .title {
                margin-left: 15px;
            }
        }
    }

    .assign_comment {
        background: #FAFAFA;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        box-shadow: 0 1px 0 #DFDFDF;
        border-radius: 3px;
        display: block;
        margin: 18px -20px;
        padding: 12px 17px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #005D92;
        position: relative;
        width: calc(100% + 40px);
        b{
            font-weight: 700;
            display: block;
        }
    }

    .mat-expansion-panel-body {
        padding: 0;
    }

    mat-progress-bar {
        height: 10px !important;
        border-radius: 10px;
        margin: auto;
        border: 1px solid rgba(15, 167, 255, 0.2);

        .mat-progress-bar-buffer {
            box-shadow: inset 0 4px 3px rgba(0, 0, 0, 0.12);
            background: #e0f9ff;
        }
    }

    .mat-progress-bar .mat-progress-bar-fill::after {
        border-radius: 13px;
    }

    .mat-card-content {
        margin-bottom: 16px;
    }

    .mat-card-actions {
        margin-bottom: 0 !important;

        button {
            display: block;
            position: relative;
            margin-left: -10px !important;
            margin-right: -10px !important;
            width: calc( 100% + 20px );
            box-shadow: none !important;

            .mat-button-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: auto;
                font-size: 15px !important;
                @extend %button-image;
                @extend %button-medium;
                @extend %button-image-medium-blue;

                .mat-icon {
                    @extend %button-image-icon;
                    min-width: 17px;
                    min-height: 17px;
                }

                span {
                    @extend %button-image-title;
                }
            }
        }
    }
}

app-concept-card {
    .mat-card.mat-card {
        border: 1px solid #DFDFDF;
        border-radius: 15px;
        -webkit-box-shadow: 0 2px 7px 0 #dfdfdf;
        box-shadow: 0 2px 7px 0 #dfdfdf;
        padding: 21px 21px 0 21px;
        margin: 0 14px calc(100% / 8);

        .mat-card-header {
            display: flex;
            flex-flow: row-reverse;
            justify-content: flex-end;
            color: #7E4E00;
            margin-bottom: 15px;

            img {
                background-color: white;
                padding: 0;
                width: 62px;
                height: 62px;
                border: 1px solid #DFDFDF;
                border-radius: 7px;
                box-shadow: 0 1px 0 0 #dfdfdf;
            }

            .mat-card-header-text {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .mat-card-title, .mat-card-subtitle {
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 26px;
                    letter-spacing: 0;
                    text-align: left;
                    margin-top: 0;
                    margin-bottom: 0;
                    color: #7E4E00;
                }

                .mat-card-title {
                    margin-top: 9px;
                }


            }
        }

        .mat-card-content {
            margin-left: -22px;
            margin-right: -22px;
            margin-bottom: 13px;
            font-size: 14px;
            background-color: transparent;
            border: 0;
            padding: 0;

            .warning-assignment-message {
                padding: 11px 20px;
                border: 1px solid #DFDFDF;
                border-radius: 7px;
                box-shadow: 0 1px 0 0 #dfdfdf;
                background-color: #FAFAFA;
                color: #7E4E00;
                margin: 0 -4px;
            }
        }

        .mat-card-actions {
            // voir mutualisation ci-dessus
            in-height: 0;
            padding: 0;
            border: 0;
            margin: 0;
            padding-bottom: calc(100% / 8.2); // moitier hauteur du bouton
        }
    }
}

app-assignments-list {
    mat-spinner {
        margin-bottom: 20px;
    }
}

app-lesson-card, app-assignments-list {
    .mat-card.mat-card {
        margin: 5px 14px calc(100% / 11);
        padding-bottom: 0 !important;
    }

    .mat-card-actions {
        button {
            .mat-icon {
                @extend %button-image-icon-svg;
            }
        }
    }
}


