.latex-wrapper {
    .katex .mathdefault {
        font-family: #{$font-family};
        font-style: normal;
    }

    .msg-icon.validated {
        display: none;
    }
}
.noselect {


}

#latex-keyboard .keyboard-grid-container, .keyboard-grid-container {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    grid-row-gap: 4px !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
    z-index: 999;
    &.alphabetic,
    &.alphabetic-maj,
    &.alphabeticUppercase {
        .validate {
            grid-column: 9 / 12 !important;
            grid-row: 4 / auto !important;
        }

        .backspace {
            grid-column: 10 / 12 !important;
            grid-row: 3 / auto !important;
        }

        .spacebar {
            grid-column: 5/9;
            grid-row: 4;
        }

        .toggleTab{
            background: url("/assets/ubolino_app/icons/shift_min.svg") no-repeat center;
            grid-column: 1 / auto !important;
            grid-row: 3 / auto !important;
        }
    }

    &.alphabetic-maj {
        .toggleTab{
            background: url("/assets/ubolino_app/icons/shift_maj.svg") no-repeat center !important;
        }
    }

    &.numeric {
        .validate {
            grid-column: 5 / 7 !important;
            grid-row: 4 / auto !important;
        }

        .backspace {
            grid-column: 5 / 7 !important;
            grid-row: 3 / auto !important;
        }
        .active {
            background: #265C8E;
            border: 1px solid #214F7A;
            box-sizing: border-box;
            box-shadow: 0px 3px 0px #214F7A;
            border-radius: 7px;
            .base {
                color: white;
            }
        }
    }

    .keyboard-grid-item .katex, .keyboard-grid-item .mathdefault {
        font: bold 20px/24px "drBuch", "Helvetica Neue", Arial, sans-serif;
        color: #005D92;
    }

    .keyboard-grid-item,
    .toggleTab,
    .spacebar,
    .keyboard-tab-title.backspace {
        background: #FFFFFF;
        border: 1px solid #E6EFF5;
        box-sizing: border-box;
        box-shadow: 0px 3px 0px #E6EFF5;
        border-radius: 7px;
        min-height: 6vh;
    }
    .validate {
        background: #4DA6F8;
        border: 1px solid rgba(38, 92, 142, 0.2);
        box-sizing: border-box;
        box-shadow: 0px 3px 0px #4597E3;
        border-radius: 7px;
    }

    .keyboard-tab-title {
        border-radius: 6px;
    }

    .keyboard-tab-title.is-active {
        background: #0FA9FF;
        border: 1px solid rgba(15, 169, 255, 0.2);
    }

    .keyboard-tab-title.backspace {
        background: url("/assets/ubolino_app/icons/erase.svg") no-repeat center;
        text-indent: -9999px;
    }
}



