@use '~@angular/material' as mat;
app-join-group {
    .success {
        color: mat.get-color-from-palette($accent);
    }
}


fuse-groups-list, module-groups-listing {

    > .content {
        overflow-y: auto;
        min-height: 0;
    }

    table {

        //.mat-checkbox-ripple { // a vérifier si utile
        //    display: none !important; //fix for broken rendering
        //}

        th, td {
            &.mat-column-avatar {
                width: 64px;
            }

            &.mat-column-color {
                text-align: center;
            }

            &.mat-column-buttons {
                width: 80px;
            }
        }

        .pastille {
            width: 40px;
            height: 40px;
        }

    }

}

%id-groups-form-dialog {
    .id-groups-form-dialog {
        display: flex;
        border: 3px solid mat.get-color-from-palette($primary);
        border-radius: 5px;
        margin-bottom: 10px;
        background-color: map_get($mat-white, 500);

        .button-color-select {
            .mat-button-wrapper {
                flex-direction: column;
                min-height: 60px;
                justify-content: flex-start;

            }
            span {
                line-height: 0.8;
                padding-bottom: 10px;
            }
            .pastille {
                height: 32px;
                width: 32px;
            }
        }

        .dialog-content-wrapper {
            @extend %fxRowSpaceBetweenStretch;
            flex: 1;

            .mat-dialog-content {
                flex: 1;

                form {
                    @extend %fxRowStartCenter;
                    padding: 5px 10px;
                    min-height: 62px;

                    > div {
                        padding: 0 5px;
                        margin-bottom: 15px !important;
                        &.field-group-color {
                            position: relative;
                        }
                    }

                    .mb-24 { //TODO remove to HTML :(
                        margin-bottom: 15px !important;
                    }


                    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
                        padding-bottom: 0;
                        position: relative;

                        .mat-form-field-flex {
                            display: inline-block;
                            margin-top: 10px;
                        }

                        .mat-form-field-infix {
                            border-top: 0;
                            width: auto;
                            min-width: 150px;
                            padding: 0.4375em 0;

                        }

                        .mat-form-field-underline {
                            bottom: 0;
                        }
                        .mat-form-field-subscript-wrapper {
                            top: calc(100% - 0.5em);
                        }

                        .mat-chip-list .mat-error {
                            position: absolute;
                            bottom: -17px;
                            left: 3px;
                            font-size: 12px;
                            white-space: nowrap;
                        }
                    }

                    .mat-form-field-label {
                        // theme
                    }

                    // chip list shrink ----------------------

                    .mat-chip-list-wrapper {
                        // flex-wrap: nowrap;
                        position: relative;

                        .mat-standard-chip {
                            flex: none;
                            margin: 0 4px 3px 4px;
                            min-height: 28px;
                        }
                    }

                    .mat-form-field-appearance-legacy .mat-form-field-type-mat-chip-list {
                        .mat-form-field-infix {
                            padding-top: 0;

                            .mat-standard-chip {
                                padding: 3px 5px 2px 12px;
                            }
                        }
                    }

                    .mat-standard-chip + input.mat-chip-input { // change input when chip is showing
                        width: 30px;
                        flex: 1 1 20px;
                        min-width: 20px;
                        flex: 0;
                        // tricks add svg colored background
                        -webkit-mask: icon("add_fill.svg") no-repeat 50% 50%;
                        mask: icon("add_fill.svg") no-repeat 50% 50%;
                        -webkit-mask-size: cover;
                        mask-size: cover;
                        -webkit-mask-size: auto 100%;
                        mask-size: auto 100%;
                        background-color: mat.get-color-from-palette($accent);

                    }
                }

            }

            .mat-dialog-actions {
                position: relative;
                display: flex;
                @extend %fxRowSpaceAroundCenter;

                padding: 0 15px;

                button {
                    @extend %mat-fab-32;
                    background-color: mat.get-color-from-palette($accent);

                    &[disabled] {
                        background-color: rgba(0, 0, 0, 0.26);
                    }

                    .mat-icon {
                        color: map-get($mat-white, 500);
                        width: inherit;
                        height: inherit;
                    }

                }

            }
        }
    }
}

%button-add-close {
    .add {
        flex: 1;
        color: white;
        align-items: center;
        display: flex;
        background-color: mat.get-color-from-palette($accent); // mv theme
        height: 62px;
        border-radius: 10px;
        margin-top: 5px;

        &.mat-raised-button:not([disabled]):active:not([class*='mat-elevation-z']) {
            box-shadow: none;
        }

        .mat-button-wrapper {
            flex-direction: row-reverse;

            span {
                margin: 0 10px;
                font-weight: bold;
            }

            .mat-icon {
                margin-right: 25px;
                margin-left: 0;
            }
        }

        .inline-learner-add {
            app-learner {
                display: flex;
                width: 100vh;
                height: 500px;
            }
        }
    }
    .close {
        position: absolute;
        background-color: transparent;
        box-shadow: none;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
        margin-right: 10px;
        .mat-icon {
            color: white;
            width: 24px;
            height: 24px;
        }

    }
}

$gradient-theme-blue-white: linear-gradient(180deg, map-get($primary, 50) 0%, rgba(0, 0, 0, 0) 50%);

#groups-list-table {

    .mat-row-editing {
        position: relative;
        min-height: 75px;

    }

    .mat-column-editing {
        min-height: 75px;
        padding: 0;
        width: 100%;
    }

    @extend %id-groups-form-dialog;

    .groups-inline-modal, .groups-inline-modal-learner {
        margin: 0;
        width: 100%;
        min-height: 75px;
        .id-groups-form-dialog {
            margin-bottom: 0;
            flex: 1;

            .mat-dialog-actions {
                position: relative;
                display: flex;
                height: auto;
                margin-bottom: inherit;

                .mat-icon {
                    color: mat.get-color-from-palette($accent);
                    height: 65px;
                    width: 65px;
                }
            }
        }
    }
    > .groups-inline-modal {
        width: calc(100% - 16px);
        margin: 0 8px;
    }

    .mat-column-addingLearner {
        background: #{$gradient-theme-blue-white}; // mv theme
        padding: 0 5px;
        .page-layout.simple {
            padding: 0 !important;
        }
    }
    .groups-inline-modal-learner {
        min-height: 50vh;
        .mat-white-bg, .mat-table { // TODO remove bad HTML class
            background-color: transparent !important;
        }
        tbody{
            height:200px;
            overflow-y:auto;
            width: 100%;
            tr {
                background-color: white;
            }
        }

        .mat-dialog-actions {
            position: relative;
            display: flex;
            @extend %button-add-close;
            .add .mat-icon {
                background-repeat: no-repeat;
                display: inline-block;
                color: mat.get-color-from-palette($mat-white, 500);

                height: 24px;
                width: 24px;
                svg {
                    fill: currentColor;
                    stroke: currentColor;
                }
            }
        }
    }

}



// groups color selection
.mat-menu-panel {
    max-width: inherit !important;

    .mat-menu-content:not(:empty) {
        padding: 10px;
    }

    .mat-radio-group {
        display: flex;
        .mat-radio-button {
            margin: 0 10px;
        }
        .mat-radio-button, .mat-radio-container, .mat-radio-outer-circle {
            width: 40px;
            height: 40px;
            padding: 0;
            border: none;
        }
        .mat-radio-checked .mat-radio-inner-circle {
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: 3px solid #00afec;
            transform: scale(1) translate(0px, 0px);
            padding: 0;
        }
    }


}

// groups form
.groups-inline-modal {
    @extend %fxColStartStretch;
    height: auto;

    @extend %id-groups-form-dialog;
    .mat-dialog-actions {
        position: relative;
        display: flex;
        @extend %button-add-close;
    }
}

app-graph-group-management {
    .underline {
        padding-bottom: 3px;
        border-bottom: 1px solid darkgrey;
    }

    .selected-exercice {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        text-align: left;
        padding-top: 6px;
    }
}
