@use '~@angular/material' as mat;
dashboard {

    .dash-widget-wrapper-parent {
        @extend %fxColStartStretch;
        place-content: stretch flex-start !important;
        align-items: stretch !important;

        .mat-accordion>.mat-expansion-panel-spacing:last-child,
        .mat-accordion>:last-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
            margin-bottom: 40px;
        }


        .mat-expansion-panel-body {
            max-height: 300px;
            overflow: auto;
            padding: 0 15px;
        }

        .dash-widget-group {
            flex-direction: row !important;
            flex-wrap: wrap !important;
        }

        .dash-widget-wrapper {

            .assignments-widget-Group {
                width: 100%;
                .dash-widget-assignments {
                    width: calc(50% - 20px);

                    &:nth-child(odd) {
                        margin-right: 40px;
                    }

                    &.dash-widget-assignments-closed {
                        width: 100%;
                    }

                }

            }

            .dash-widget-news {
                flex: 1 1 100%;
                width: 70%;
                padding: 0 15% 20px 15%;
                min-width: 500px;

                &.news-list-length-0 {
                    display: none;
                }

                mat-card-header {
                    display: none !important;
                }

                app-widget-news {
                    & > mat-icon {
                        position: absolute;
                        top: -10px;
                        right: -10px;
                        color: mat.get-color-from-palette($accent);

                        circle {
                            fill: white
                        }
                    }

                    .news-list {
                        padding: 20px;

                        & > * {
                            padding: 5px;
                            display: flex;
                            flex-direction: column;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            align-items: center;
                            align-content: stretch;

                            button {
                                border-radius: 0;
                            }
                        }
                    }
                }
            }

            .dash-widget-group {
                align-items: self-start;
                flex: 1 1 100%;
            }
        }

    }

    .page-layout.simple.fullwidth {
        > .header {
            height: auto;
            justify-content: center !important;
            H2 {
                font-size: 38px;
            }
        }

        .content {

            > div {
                height: 100%;
                padding: 20px;

                > .dash-widget-wrapper-parent {
                    padding: 20px;
                    .dash-widget-wrapper {

                        .dash-widget-recommendation {
                            order: 2;
                            flex: 1 100%;
                            padding-bottom: 20px;
                            .mat-card-header {
                                display: none !important; // TODO remplacer par un setting ?
                            }
                        }
                        .dash-widget-news {
                            order: 1;
                            .news-list {
                                font-size: 24px;
                                font-weight: 400;
                                text-align: center;
                            }
                        }
                        .dash-widget-group.assignments-widget-Group {
                            order: 3;
                            gap: 20px;
                            .dash-widget-assignments {
                                margin: 0 !important;
                                flex: 1;
                            }
                            .dash-widget-assignments-closed {
                                width: auto !important;
                                flex: 1;
                            }
                        }

                    }
                }
            }
        }
    }

}