@use '~@angular/material' as mat;
app-graph-group-management {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;


    .mat-tab-links {
        justify-content: center;

        button {
            color: #15213F;
        }
    }

    .mat-tab-nav-bar {
        border-bottom: none;

        &.mat-primary .mat-ink-bar  {
            background-color:  mat.get-color-from-palette($accent, 500);
        }
    }

    //.navbar {
    //justify-content: center;
    //
    //button {
    //    position: relative;
    //    @extend %mat-tab-label;
    //
    //    box-shadow: none;
    //
    //    .mat-button-wrapper {
    //        border-radius: 0;
    //    }
    //
    //    &.active::after {
    //        position: absolute;
    //        content: '';
    //        display: block;
    //        height: 2px;
    //        width: 100%;
    //        background-color: mat-color($accent, 500);
    //        left: 0;
    //        bottom: 0;
    //    }
    //}

    //}

    .action-button {
        padding: 5px;

        button {
            min-height: 36px;
            padding: 5px 20px;

            border-radius: 2em;
            background-color: mat.get-color-from-palette($accent, 500);

            font-weight: 700;
            color: white;

            span {
                width: 10em;
                white-space: normal;
                line-height: 1.1em;
            }
        }

    }

}