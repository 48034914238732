app-true-false {
    display: flex;
    @extend %activities-card;

    .mat-card .mat-card-content app-consignes {
        .container {
            width: 100%;
        }
        .instruction {
            @extend %textCartouche;
            &.is-picture {
                background: transparent !important;
            }
        }
    }


    .mat-card.activities-card.two-columns-layout .answer-state-msg {
        flex: none;
        margin: 0;
    }
    .mat-card.activities-card.two-columns-layout .mat-card-content {
        app-consignes {
            flex: 1 1 auto;
            align-items: center;
        }
    }

    .mat-card mat-card-content.mat-card-content #container-for-answers-available.columns-1 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: row;
        align-content: flex-start;
    }

    .mat-card.activities-card, .mat-card.activities-card.two-columns-layout {
        #container-for-answers-available {
            flex: 2;
            align-content: flex-start;
            flex-direction: row;
            margin: 0;

            button {
                &.btn-option {
                    @extend %button-image;
                    @extend %button-half-square;
                    @extend %button-half-square-blue;
                    flex: 1 1 49%;
                    max-width: 120px;
                    height: auto;
                    margin: 0 !important;
                    padding: 0 !important;
                    position: relative;

                    .mat-icon {
                        font-size: 52px;
                        width: 52px;
                        height: 52px;
                        min-width: 52px;
                        min-height: 52px;
                        line-height: 52px;
                        margin: 0 auto;
                        &::before {
                            display: block;
                            content: '';
                            width: 100%;
                            height: 100%;
                            background: iconApp('true.svg') no-repeat center !important;
                        }
                    }
                    &:first-child {
                        .mat-icon {
                            width: 42px;
                            height: 42px;
                            min-width: 42px;
                            min-height: 42px;
                            &::before {
                                background: iconApp('cross.svg') no-repeat center !important;
                            }
                        }
                    }
                    &.incorrect {
                        @extend %button-half-square-grey;
                    }
                    &.currently-correct {
                        @extend %button-half-square-green;
                        color: white;
                    }
                }
            }
        }
    }



}