app-followed-tabs {
    justify-content: space-evenly;
    background-color: white;

    .mat-tab-group.mat-primary .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary .mat-ink-bar {
        background-color: #fcb731;
    }
    .mat-tab-labels {
        justify-content: center;
    }

    app-followed-list, app-followed-log-book {
        .content {
            display: initial !important;
        }

        mat-spinner{
            margin: auto;
        }
    }

    #id-app-followed-log-book { // news best selector sonar compatiblity
        table tr, table tr.mat-row {
            cursor: default;
        }
    }
}
