 app-home-page {
    .banner{
        width: 100%;
        position: relative;
        height: 250px;
        img {
            width: 100%;
            height: 100%;
            &.logo-inside-banner{
                width: 140px;
                position: absolute;
                bottom: 20px;
                right: 20px;
                height: auto;
            }
        }
        margin-bottom: 10px;
        background-position:50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .title{
        text-decoration: none;
        display: inline-block;
        position: relative;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        letter-spacing: 1.5px;
        color: map-get($primary, 500);
        margin-bottom: 20px;
        line-height: 40px;
        text-align: center;
    }
    .content{
        p{
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 1.5px;
            color: map-get($primary, 500);
            margin: 5px;
        }
    }
    .mat-card {
        padding: 0;

        .wrapper{
            .wrapper-content{
                height: 100%;
                padding: 8px;
            }
        }
        .mat-card-header {
            .mat-card-title {
                margin: 0;
                color: #fff;
            }
        }

        .mat-card-img{
            color: #fff;
            height: 268px;

            img{
                margin-bottom: 10px;
            }
        }
        .mat-card-content{
            flex: 1 1 auto !important;
            height: 200px;
            overflow: auto;
        }
        .mat-card-actions{
            margin: 0;
            padding: 0 8px;
            flex: 0 0 auto !important;
            button {
                border-radius: 16px;
            }

        }
    }
}
