
/* ***************************************************************************** */
@use '~@angular/material' as mat;
// lessons-recap
/* ***************************************************************************** */

app-lessons-recap,
 {
    flex: 1;
    .content {
        display: flex;
        flex-direction: column;
        flex: 1;

        box-sizing: border-box;

        > div:first-child { // pas bon
            display: flex;
            flex: 1 100%;
            flex-direction: column;
            overflow-y: auto;
            align-items: center;
            .mat-icon {
                width: 80px;
                height: 80px;
            }
        }
    }
}

/* ***************************************************************************** */
// new-app-lessons
/* ***************************************************************************** */

new-app-lessons {

    mat-card {
        button {

        }
    }

}

/* ***************************************************************************** */
// app-lessons
/* ***************************************************************************** */

fuse-app-lessons,
 {
    .invisible {
        display: none;
    }

    .fuse-app-lessons-header {
        color: #fff;
        background: mat.get-color-from-palette($primary);
        mat-icon {
            path {
                fill: mat.get-color-from-palette($primary);
            }
        }
    }
}

// Stepper css start

.mat-vertical-content {
    display: none;
    padding: 0 !important;
}
// Stepper css end

.spinner-host {
    height: 100vh;
}




/* ***************************************************************************** */
// lessons-list
/* ***************************************************************************** */


app-edit-lesson-dialog {
    span.mat-hint {
        max-width: 370px;
        margin-top: -15px;
        font-size: 90%;
    }

    .mat-select-panel.mat-primary .mat-select-content, .mat-select-panel.mat-primary .mat-select-panel-done-animating{
        background-color: #757575;
    }
}

app-lesson-activities {
    .itemType .mat-icon {
        height: 48px;
        width: 48px;
        padding: 6px;
        svg path {
            fill: mat.get-color-from-palette($primary);
        }
    }

    .arrow{
        background: red;
        cursor: pointer;
    }
}

app-lesson-metadata-dialog {

    .details-container{
        position: relative;
        border: 1px solid rgb(212, 212, 212);
        padding: 10px 25px;
        border-radius: 5px;
        text-align: justify;
        margin: 30px 0;
        .details-header{
            top: -14px;
            left: 9px;
            position: absolute;
            background: white;
            padding: 0 7px;
            font-size: large;
        }
    }
}

/* ***************************************************************************** */
// lessons-recap
/* ***************************************************************************** */


$validated-color: #008A9C; //  cyan 600 #00ACC1
$warning-color: #ED6723;
$success-color: #679A00;
$error-color:  darkred;


app-lessons-recap {

    .marg-auto{
        margin:auto;
    }
    .heading{
        font-size: 24px;
        color: #000;
    }
    .para{
        font-family: 'nexa', "Helvetica Neue", Arial, sans-serif;
        font-size: 14px;
        color: #000;
    }

    .text-center {
        .flag-green {
            color: $success-color;
        }
        .validated-message {
            color: $success-color;
        }
    }
    .home-correction {
        display: flex;
        margin: 10px 25%;
        .validated-message {
            float: left;
        }
        .hint-message {
            float: left;
            margin: 0 8px;
        }
    }
    #review{
        display: flex;
        justify-content: space-between;
        width: 75%;
        margin: 4em auto 5em;
        list-style: none;
        padding: 0;
        li{
            width: 45px;
            height: 45px;
            border: 2px solid $validated-color;
            border-radius: 50%;
            text-align: center;
            line-height: 40px;
            position: relative;
            background-color: #fff;
            z-index: 0;
            font-weight: bold;
            cursor: pointer;
            padding: 0;
            font-size: 18px;
            color: $validated-color;
            background-image: none;

            .mat-icon {
                content: ' ';
                width: 24px;
                height: 24px;
                position: absolute;
                border-width: 1px;
                border-style: solid;
                border-color: $validated-color;
                border-radius: 20px;
                top: -10px;
                right: -10px;
                background-position: center;
                background-size: 15px;
                background-repeat: no-repeat;
                background-color: white;

                align-items: center;
                justify-content: center;
                display: flex;
                svg {
                    width: 18px;
                    height: 18px;
                    path {
                        fill: $validated-color;
                    }
                }
            }
            span{
                position:absolute;
                width: 25px;
                height: 25px;
                border: 2px solid #800080;
                border-radius: 50%;
                text-align: center;
                line-height: 30px;
                left: 30px;
                top: -12px;
                background-color: #fff;
                z-index: 1;

                .mat-icon{
                    font-size: 18px;
                    width: 20px;
                    height: 20px;
                    min-width: 20px;
                    min-height: 20px;
                    font-weight: bold;
                }
            }
            &.validated{
                background-color: $validated-color;
                color:#ffffff;
                border-color: $validated-color;

                .mat-icon {
                    border-color: $validated-color;
                    background-position: center;
                    svg path {
                        fill: $validated-color;
                    }
                }
            }
            &.warning{
                border-color: $warning-color;
                color: $warning-color;

                span{
                    border-color: $warning-color;
                    color: $warning-color;
                    .mat-icon{
                        color: $warning-color;
                    }
                }
                &.active{
                    background-color: $warning-color;
                    color:#ffffff;
                }
                .mat-icon {
                    border-color: $warning-color;
                    svg path {
                        fill:  $warning-color;
                    }
                }
            }
            &.danger{
                border-color: $error-color;
                color: $error-color;
                span{
                    border-color: $error-color;
                    color: $error-color;
                    .mat-icon{
                        color: $error-color;
                    }
                }
                &.active{
                    background-color: $error-color;
                    color:#ffffff;
                }
                .mat-icon {
                    border-color:  $error-color;
                    width: 16px;
                    height: 16px;

                    svg {
                        width: 12px;
                        height: 12px;
                        path {
                            fill:  $error-color;
                        }
                    }
                }
            }
            &.success{
                border-color: $success-color;
                color: $success-color;
                span{
                    border-color: $success-color;
                    color: $success-color;
                    .mat-icon{
                        color: $success-color;
                    }
                }
                &.active{
                    background-color: $success-color;
                    color:#ffffff;
                }
                .mat-icon {
                    border-color:  $success-color;
                    svg {
                        width: 12px;
                        height: 12px;
                        path {
                            fill:  $success-color;
                        }
                    }
                }

            }

        }
    }
    .header-icon{
        font-size: 80px;
    }
    .result-screen-btn {
        display: flex;
    }
    .lesson-play-toolbar{
        display: inline-block;
        width: 100%;
        .icon-wrapper{
            width: 40px;
            height: 40px;
            border: 2px solid #ffffff;
            border-radius: 50%;
            line-height: 35px;
            float: right;
            .mat-icon{
                color: #000;
            }
        }
    }
}

fuse-app-lessons {
    .outlet-player-navigation {
        display: flex;
        flex-direction: row;
        > *:not(router-outlet) { // enlarge any children components
            flex: 1;
        }
        // except navigation elements
        > .previous-wrapper, > .next-wrapper, > .previous, > .next, > .preview-wrapper, > .preview {
            flex: none;
        }
        .previous,
        .next {
            flex-direction: column;
            box-sizing: border-box;
            display: flex;
            place-content: stretch center;
            align-items: stretch;
            flex: 0 0 auto;
        }
        .previous {
            padding-left: 10px;
        }
        .next {
            padding-right: 10px;
        }
    }
}



