// TODO 2021 Commenter + plan et réordonner les css
@use '~@angular/material' as mat;
/* ************************* PLAN **************************
*   - mixins
*   - placeholder
*   // %name{prop} ==> .maClass { @extend %name }
*   BP: "si vous avez besoin de variables, utilisez un mixin. Sinon, étendez un placeholder."
*
*   // TODO ordre d'organisation css
*   (Atomes -> /core) (p a h1 td b table ...)
*                                                     button
*
*   (Molécules -> /core = material element) (^override)
*                                        .mat-actions button
*
*   (Organismes -> /core = material element) (^override)
*                              .mat-card .mat-actions button
*
*   (Template) (^override)
*           page-layout.simple .mat-card .mat-actions button
*
*   (Pages) (^override)
* component page-layout.simple .mat-card .mat-actions button
*
*/

/* ----- mixins ----- */
@mixin sizeFix($width, $height: $width) {
    width: $width;
    height: $height;
    min-width: $width;
    min-height: $height;
}
/* ----- placeholder ----- */

// Transforme nos boutons raised en mat-fab de taille 32px
%mat-fab-32 {
    border-radius: 50%;
    @include sizeFix(32px);
}


/* ----- PLACEHOLDER local ----- */
// TODO 2021 supprimer le Raised du html pour l'appliquer via le HTML via placeholder
button,
button.mat-raised-button,
button.mat-stroked-button,
button.mat-flat-button,
button.mat-fab,
button.mat-mini-fab,
a.mat-raised-button,
a.mat-stroked-button,
a.mat-flat-button,
a.mat-fab,
a.mat-mini-fab {

    & + &:not(.mat-menu-item) {
        margin-left: 10px;
    }

    .mat-button, .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0;

        mat-icon {
            display: flex;
            align-self: center;
            margin: 0;
        }

        mat-icon + span, span + mat-icon {
            margin-right: 0;
            margin-left: 5px;
        }
    }

    &.flag {
        .mat-icon {
            path {
                fill: map-get(mat.$grey-palette, 300);
            }
        }

        &.active, &:hover {
            .mat-icon path {
                fill: mat.get-color-from-palette($primary);
            }
        }
    }
}

button, a {

    mat-spinner {
        width: 24px !important;
        height: 24px !important;

        svg {
            width: 24px !important;
            height: 24px !important;
        }
    }

    &[disabled], &.mat-primary[disabled], &.mat-accent[disabled], &.mat-warn[disabled] {
        mat-icon.mat-icon {
            color: rgba(0, 0, 0, 0.26);

            svg path {
                fill: rgba(0, 0, 0, 0.26);
            }
        }
    }

    &.mat-raised-button, &.mat-flat-button {
        &.mat-primary {
            background-color: mat.get-color-from-palette($primary);
            color: #fff;

            .mat-icon {
                color: #fff;
            }

            svg path {
                fill: #fff;
            }
        }

        &.mat-accent {
            background-color: mat.get-color-from-palette($accent);
            color: #fff;
        }
    }

    &.mat-fab, &.mat-mini-fab {
        color: #fff; // TODO à virer pour profiter de la couleur du text par default

        &.mat-primary {
            color: #fff;

            .mat-icon {
                color: #fff;
            }

            svg path {
                fill: #fff;
            }
        }

        &.mat-accent {
            color: #fff;
            background-color: mat.get-color-from-palette($accent);

            .mat-icon {
                color: #fff;
            }

            svg path {
                fill: #fff;
            }
        }

        &.mat-warn {
            .mat-icon {
                color: mat.get-color-from-palette($warn);
            }

            svg path {
                fill: mat.get-color-from-palette($warn);
            }
        }

        &[disabled], &.mat-primary[disabled], &.mat-accent[disabled], &.mat-warn[disabled] {
            .mat-icon {
                color: rgba(0, 0, 0, 0.26);
            }

            svg path {
                color: rgba(0, 0, 0, 0.26);
            }
        }

        &.cdk-program-focused { // at start on display by app
            .mat-button-focus-overlay {
                opacity: 0; // don't focus when button appears
            }
        }

        &.cdk-mouse-focused { // when you click on
            .mat-button-focus-overlay {
            }
        }
    }

    // Specifics button
    .mat-mini-fab.close {
        background-color: white;
        margin-right: 0;
        @include mat.elevation(1);

        &:not([class*=mat-elevation-z]) {
            @include mat.elevation(1);
        }

        &.cdk-focused { // anything focus on
            .mat-button-focus-overlay { // default style
                opacity: 1;
            }

            &.cdk-program-focused { // at start on display by app
                .mat-button-focus-overlay {
                    background-color: rgba(#ff00f2, .12);
                    opacity: 0; // don't focus when button appears
                }
            }

            &.cdk-mouse-focused { // when you click on
                .mat-button-focus-overlay {
                }
            }
        }

        .mat-mini-fab.cdk-keyboard-focused .mat-button-focus-overlay, .mat-mini-fab.cdk-program-focused .mat-button-focus-overlay {
            // is set on builder theming.scss
        }
    }

    .mat-icon-button {
        margin: 0;

        &.mat-primary {
            .mat-icon {
                color: mat.get-color-from-palette($primary);
            }

            svg path {
                fill: mat.get-color-from-palette($primary);
            }
        }

        &.mat-accent {
            .mat-icon {
                color: mat.get-color-from-palette($accent);
            }

            svg path {
                fill: mat.get-color-from-palette($primary);
            }
        }

        &.mat-warn {
            .mat-icon {
                color: mat.get-color-from-palette($warn);
            }

            svg path {
                fill: mat.get-color-from-palette($warn);
            }
        }

        &[disabled], &.mat-primary[disabled], &.mat-accent[disabled], &.mat-warn[disabled] {
            .mat-icon {
                color: rgba(0, 0, 0, 0.26);
            }

            svg path {
                color: rgba(0, 0, 0, 0.26);
            }
        }
    }

    .mat-button:hover {
        .mat-button-focus-overlay {
        }

        svg {
            fill: white;

            &#Calque_1 { // TODO utiliser un autre sélecteur et/ou nettoyer svg
                color: #00E998;
                fill: white;
            }

            path {
                color: #00E998;
                fill: black;
            }
        }

        #Calque_1 { // TODO utiliser un autre sélecteur et/ou nettoyer svg
            color: #00E998;
            fill: white;
        }
    }
}

span.mat-mini-fab {
    display: flex;
    align-items: center;
    justify-content: center;
}

.addBtnBottomRight { // TODO à refactoriser pour le positionnement en bas à droite des boutons d'ajout sur toutes les pages
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 0;
    z-index: 99;
}

.mat-fab.mat-fab-bottom-right { // TODO à refactoriser pour le positionnement en bas à droite des boutons d'ajout sur toutes les pages
    top: auto;
    right: 20px;
    bottom: 20px;
    left: auto;
    position: absolute;
}

.mat-button-toggle { // Button group dans filtres (select + reset)
    &:first-child {
        border-right: 2px solid #008a9c;
    }

    .mat-button-toggle-button {
        height: 100%;
    }

    .mat-button-toggle-label-content span + .mat-icon {
        margin-left: 8px;
    }

    &.mat-raised-button {
        margin-right: 0;
        padding: 0;
    }
}

.mat-toolbar {
    .help {
        background-color: white;
        color: black;
        border-radius: 30px;
        margin-left: 10px;
        width: auto;
        padding: 0 15px;
    }
}

