%AnswersThumb {
    display: flex;
    column-gap: 5px;
    margin: 0;
    position: relative;
    width: 100%;
    max-width: 430px;
    margin-top: 0 !important;
    flex: 0 1 50%;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    justify-content: center;

    .pristine, .currently-correct, .was-correct, .incorrect {
        flex: 0 1 49%;
        flex-wrap: wrap;
        height: auto;
        border: 4px solid #0FA9FF;
        border-radius: 10px;
        padding: 0 !important;
        box-sizing: border-box;
        margin-bottom: 12px;
        box-shadow: 0 3px 20px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
        background: transparent !important;
        position: relative;

        &::before {
            display: none;
        }

        label {
            display: inline-block;
            padding: 10px;
            min-height: 50px;
        }

        img {
            width: auto;
            height: 100%;
            border-radius: 7px;
        }
    }

    .currently-correct {
        border: 4px solid #89E30C;
    }

    .was-correct {
        border: 4px solid #89E30C;
        cursor: not-allowed;
    }

    .incorrect {
        border: 4px solid #FF6862;
    }
}

%textCartouche {
    background-color: rgba(15, 169, 255, 0.1);
    padding: 20px 20px 20px 20px;
    text-align: center;
    border-radius: 10px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #005D92;
}

.guessing-item,
%wording, wording_placholder {
    color: #005D92;

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;

    //@extend %textCartouche;

    p {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
    }

    &.tts {
        cursor: pointer;

        > .wording {
            padding-bottom: 5px;
        }
    }
}

%instruction, instruction_placholder {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;

    p {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
    }
}

%activities-card, ACTIVITIES-CARD {
    .mat-card {
        height: auto;
        padding: 0;
        margin: 0;

        &.activities-card.two-columns-layout .mat-card-content {
            margin-left: 0;
        }

        .mat-card-header {
            flex: none !important;
            align-items: flex-start;
            min-height: 60px;
        }

        .mat-card-content {
            flex: 1 !important;
        }

        .mat-card-footer {
            flex: none !important;
        }

        app-consignes, app-consignes.centeredContent {
            justify-content: center;
            margin: 0;
            padding: 0;

            .wording {
                margin: 0;
                padding: 0 0 5px 0;
                @extend %wording;
            }

            .instruction {
                margin: 0 0 0 0;
                padding: 0;
                @extend %instruction;
            }

            // use to read some text not visible with text to speach
            .precision {
                opacity: 0;
                position: absolute;
                top: -9999px;
                left: -9999px;
            }


        }

        &.activities-card .answer-state-msg,
        &.activities-card.two-columns-layout .answer-state-msg {
            flex: none;
        }

        &.activities-card.two-columns-layout {
            flex-direction: column !important;
            margin: 0 !important;

            .mat-card-header {
                flex: none;
                margin-right: 0;
            }
        }

        .mat-card-actions, &.mat-card-actions {
            display: none;
        }

        mat-card-content.mat-card-content {
            padding: 0;
            margin: 0;

            app-consignes {

                > div p {
                    @extend %textCartouche;

                    &.is-picture {
                        background: transparent !important;
                    }
                }
            }

            img {
                border-radius: 10px;
            }

            .guessing-item {
                flex: 1;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                text-align: center;
                //padding-bottom: 30px;

                flex: none;
                @extend %textCartouche;

                &.is-picture {
                    background: transparent !important;
                }

                img {
                    max-height: 30vh;
                }
            }

            .available-answers, #container-for-answers-available {
                flex: 0 1 50%;
                align-items: flex-start;
                align-content: flex-start;
                margin-top: 20px;
                flex-wrap: wrap;
                // justify-content: center;
                justify-content: flex-start;
                align-content: flex-start;

                &.columns-1 {
                    align-content: center;
                }

                &.columns-2 {
                    align-content: center;
                }

            }
        }

        .answer-state-msg {
            display: none;
        }

        mat-card-footer.mat-card-footer {
            // height: 39px;
        }

    }
}

.visible {
    visibility: visible;
}

.invisible {
    visibility: hidden;
    display: block !important; // because default css and fuse helper display: none;
}

#editor-activities {

    .sidebar {
        padding: 13px;
        background-color: map-get($primary, 500);
        color: white;
    }

    .center {
        .filters {
            margin: 0 0 0 25px;
        }

        table {
            p {
                margin: 4px 0;
            }

            tr.mat-row {

                &.selected, &:hover {
                    background-color: map-get($primary, 500);
                    color: white;

                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;

                    &:after {
                        content: ' ';
                        display: block;
                        position: absolute;
                        right: -2px;
                        width: 34px;
                        height: 56px;
                        background-color: map-get($primary, 500);
                    }

                    td {
                        color: white;

                        &:last-child {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                            color: white;
                        }
                    }

                    button.mat-mini-fab.mat-accent {
                        background-color: map-get($mat-white, 500);

                        .mat-icon {
                            color: map-get($primary, 500);
                        }
                    }
                }

                button.mat-mini-fab.mat-accent {
                    background-color: map-get($primary, 500);

                    .mat-icon {
                        width: 24px;
                        height: 24px;
                        margin: 5px;
                    }

                    path {
                        fill: none;
                    }
                }
            }


        }
    }
}

app-recommendation {
    > .page-layout > .content {
        display: flex;
        flex-direction: column;
        padding: 16px;

        .mat-dialog-content {
            flex: 1;
        }

        .spinner-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            min-height: 125px;
        }

    }

    .filters {
        display: flex;

        .mat-toolbar-row {

            .mat-select {
                flex: 1;
            }

            button {
                margin-left: auto;
            }
        }
    }

    thead {
        mat-icon {
            svg {
                path {
                    fill: map-get($mat-white, 500);
                }
            }

            &.active {
                svg {
                    path {
                        fill: map-get($accent, 900);
                    }
                }
            }
        }
    }

    .see-more {
        margin: auto 45%;
        background-color: map-get($accent, 500);
        color: white;
    }
}

.count-lesson-selected {
    border-radius: 18px;
    padding-right: 4px;

    span {
        color: map-get($mat-white, 500);

        .count-title {
            &:after {
                content: ' : '
            }
        }

        .count {
            color: map-get($accent, 900);
            background: map-get($mat-white, 500);
            border-radius: 20px;
            height: 30px;
            min-width: 30px;
            line-height: 30px;
            text-align: center;
            margin-left: 5px;
        }
    }
}

mat-dialog-container.mat-dialog-container app-editor-activities-list .mat-dialog-content {
    MAX-HEIGHT: 30VH !important; // patch TODO revoir l'imbrication des elements flex
}

app-activities-home {
    width: 100%;
}

app-launcher-button { // = mat-card-actions
    display: block;
    position: relative;
    top: 0;

    @extend %buttonWideHeight;
    padding: 5px 40px 6.2% 40px;
    margin-bottom: 20px;
    &.lineText2, &.lineText3, &.lineText4 {
        &::before {
            padding-top: calc(100% / 3.5);
        }
    }

    .mat-button-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: auto;

        @extend %button-image;
        @extend %button-wide;
        @extend %button-image-wide-blue;

        &.lineText2, &.lineText3, &.lineText4 {
            @extend %button-image-wide-blue-double;
            &::before {
                padding-top: calc(100% / 4.5);
            }
        }

        > span {
            text-align: start;
        }

        &::before {
            padding-top: calc(100% / 9);
        }
    }

    /*star icon*/
    .reward-container {
        display: flex;
        justify-content: end;
        margin-left: auto;

        position: absolute;
        top: 46%;
        right: 29px;
        transform: translateY(-50%);

        mat-icon {
            position: relative !important;
            width: 24px;
            height: 24px;
            min-width: 24px;
            min-height: 24px;
            font-size: 24px;
            line-height: 24px;
            margin-right: 4px;

            &:last-child {
                margin-right: 0;
            }

            &[svgicon="star_0"] {
                width: 24px;
                height: 24px;
                min-width: 24px;
                min-height: 24px;
                font-size: 24px;
                line-height: 24px;
                margin-right: 4px;


            }
        }

        .diamond {
            left: -18px;
            bottom: 3px;
            width: 50px;
            height: 50px;
        }
    }

    .icon-before-question-set {
        margin-top: -6px !important;
        width: 10px !important;
        min-width: 10px !important;
    }

    .title_container {
        display: flex;
        align-items: center;
        flex: 3;
        margin-left: 40px;
        margin-top: 12px;
    }

    .title {
        margin-right: 90px;
    }

    .first_launch {
        margin-left: 18px;
    }
}

// blur modal backdrop and apply more dark background
.backdrop-blur {
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(10px);
    background: rgba(0, 0, 0, 0.50);
}

.transparent-container-launcher {
    .container {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
    }

    .header {
        margin-left: 10px;
        padding-bottom: 10px;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        line-height: 17px;
        color: #005D92;

        mat-icon {
            height: 10px;
            color: #FF6862;
            width: 10px;
            min-width: 0;
            min-height: 0;
            margin-top: -5px;
            margin-right: 5px;
        }
    }

    .content {
        display: flex;
    }

    .progress-number {
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #005D92;
    }

    .mat-progress-bar {
        display: flex;
        width: 80%;
        margin: auto;
        height: 10px;
        border-radius: 13px;
    }

    .mat-progress-bar-buffer {
        border: 1px solid #0FA9FF;
        background: #e7f5fe;
        box-shadow: inset 0px 4px 3px rgba(0, 0, 0, 0.12);
    }

    .mat-progress-bar .mat-progress-bar-fill::after {
        box-shadow: inset 0px 4px 3px rgba(0, 0, 0, 0.12);
    }

    .mat-progress-bar .mat-progress-bar-element, .mat-progress-bar .mat-progress-bar-fill::after {
        border-radius: 13px;
    }
}

app-header {
    app-progress {
        .container {
            background: #ffffff; // todo  change with palette when will exist
            border: 3px solid #743F90; // todo  change with palette when will exist
            box-sizing: border-box;
            border-radius: 1em;
            display: flex;
            align-content: flex-end;
            flex-wrap: nowrap;
            width: 100%;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 16px;
            text-align: center;
        }

        .level {
            flex: 2;
            color: #743F90; // todo  change with palette when will exist
            padding: 2px 10px 0 10px;
        }

        .coins {
            display: flex;
            background: #743F90;
            border-radius: 30px;
            color: white;
            padding-left: 15px;
            margin-top: -3px;
            margin-bottom: -3px;

            span {
                display: inline-block;
                margin-right: 5px;
                padding: 2px 0 0 0;
            }
        }
    }
}

app-multimedia,
app-true-false,
app-qcu,
app-qcm,
app-text-matching,
app-image-zoning {
    @extend %activities-card;

    .available-answers {
        width: 100%;

        &.columns-1 {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: stretch;
            align-content: stretch;
        }

        &.columns-2 {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: stretch;
            align-content: stretch;

            button {
                width: 50%;
                margin: 0;
                box-shadow: none;
            }
        }

    }

    #container-for-answers-available-text-version {
        display: flex;
        flex: none;
        flex-direction: row;
        justify-content: center;
        background-color: #FFFFFF;
        padding: 20px;
        text-align: center;
        border-radius: 10px;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        color: white;
        border: solid 3px #0FA9FF;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);

        .available-answers {
            padding: 30px 0 30px 0;
        }

        button {
            background: #FFFFFF;
            background-image: none !important;
            color: white;
            min-width: 0;

            span {
                font-size: 22px;
                color: #005D92;
            }

            &.was-correct {
                background: #FFFFFF !important;
            }
        }

        &.not-correct {
            border: solid 3px #565656;

            button {
                opacity: 0.3 !important;

                span {
                    color: #565656 !important;
                }
            }

            button.incorrect {
                opacity: 1;
            }
        }

        &.correct {
            border: solid 3px #89E30C;

            button {
                opacity: 0.3 !important;

                span {
                    color: #565656 !important;
                }

            }
        }

        button.currentlyCorrect, button.was-correct {
            opacity: 1;

            span {
                color: #89E30C !important;
            }
        }
    }
}

app-qcu,
app-qcm,
app-true-false,
app-fill-in-blanks,
app-image-zoning {
    display: flex;

    #container-for-answers-selected,
    #container-for-answers-available {
        display: flex;
        column-gap: 5px;
        flex-wrap: wrap;

        flex: 1;
        margin: 0;
        position: relative;


        > div {
            position: relative;
            overflow: hidden;

            &.currently-correct {
                border: 3px solid #89E30C;
            }

            &.was-correct {
                border: 3px solid #89E30C;
                cursor: not-allowed;
            }

            &.incorrect {
                border: 3px solid #FF6862;
            }
        }

        img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    #container-for-answers-selected {
        align-content: center;
        flex-grow: 0;
        justify-content: space-around;
        margin-bottom: 50px;


        > div {
            width: 55px;
            overflow: visible;
            flex: 0;
            margin: 0;
            border-radius: 15px;
            position: relative;
            color: #fff;

            display: flex;
            justify-content: center;

            &:before {
                display: block;
                content: ' ';
                width: 100%;
                height: 55px;
                padding-top: 100%;
                background: #e7f6ff;
                border-radius: 15px;
            }

            img {
                height: 100%;
                width: auto;
                border-radius: 10px;

            }

            .answer-position {
                flex-direction: row;
                box-sizing: border-box;
                display: flex;
                place-content: center;
                align-items: center;
                border-radius: 50%;
                position: absolute;
                width: 30px;
                height: 30px;
                background: #0d91c0;
                color: white;
                top: 100%;
                left: 50%;
                transform: translate(-50%, -50%);

                &.current {
                    background: #FED501;
                }
            }
        }
    }

    #container-for-answers-available {
        place-content: center space-between;
        flex-wrap: wrap;

        > div {
            flex: 0 1 49%;
            flex-wrap: wrap;
            height: auto;
            border: 3px solid #0FA9FF;
            //border-radius: 30px;
            padding-top: 50%;
            box-sizing: border-box;
            margin-bottom: 12px;

            img {
                width: auto;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }

        }
    }

    .mat-card {
        &.two-columns-layout {
            #container-for-answers-selected, CSSACTIVITIES {
                flex-direction: column;

                > div {
                    flex: auto;
                    height: 40px;
                    width: 100%;
                    background: rgba(15, 169, 255, 0.1);
                    margin-bottom: 5px;
                    //border-radius: 30px;
                    position: relative;

                    .answer-position {
                        display: flex;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                    }
                }
            }

            #container-for-answers-available {
                place-content: center;

                button {
                    background: #0FA9FF;
                    border-radius: 30px;
                    margin-bottom: 10px;
                    max-width: 49%;
                    padding: 5px;
                    color: #fff;

                    &:first-child {
                        margin-left: 0;
                    }

                    &.currently-correct {
                        background: #89E30C;
                    }

                    &.was-correct {
                        background: #89E30C;
                        cursor: not-allowed;
                    }

                    &.incorrect {
                        background: #FF6862;
                    }
                }
            }
        }
    }
}

@import '_text-matching';
@import '_order-matching';
@import '_true-false';
@import '_short-answer';
@import '_multimedia';

app-my-progress {
    padding-top: 140px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
}

mat-spinner {
    margin: auto;
}

app-my-progress-play-button, app-continue-bar {
    margin-top: 40px;

    .play_button {
        button {
            box-shadow: none !important;
            width: 100%;
            display: block;
            position: relative;
            top: 0;
            padding: 0;

            .mat-button-wrapper {
                position: relative;
                top: 0;
                left: 0;
                right: 0;
                width: auto;

                @extend %button-image;
                @extend %button-big;
                @extend %button-image-big-purple;

                .mat-icon {
                    @extend %button-image-icon;
                }

                span {
                    @extend %button-image-title;
                    @include media-breakpoint-down('sm') {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .info-lesson {
        background: #743f901f;
        position: absolute;
        padding: 2px 2px 10px 5px;
        margin-left: 5%;
        margin-top: -16px;
        z-index: -1;
        border-radius: 9px;

        @media (min-width: 960px) {
            margin-top: -11px;
        }

        span {
            color: #743F90;
        }
    }
}

app-my-current-progress {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 15px;
    box-shadow: 0 9px 10px rgba(0, 0, 0, 0.05), 0 2px 3px rgba(0, 0, 0, 0.02), 0 1px 1px rgba(0, 0, 0, 0.01), 0 0 0 rgba(0, 0, 0, 0.005);
    display: flex;
    flex-direction: column;
    padding-top: 45px;
    padding-bottom: 50px;
    margin-left: 5px;
    margin-right: 5px;

    .container {
        color: black;
    }

    .mat-progress-bar {
        height: 1.2rem;
    }

    .mat-progress-bar-buffer {
        background: #e7f5fe;
        box-shadow: inset 0px 4px 3px rgba(0, 0, 0, 0.12);
    }

    .mat-progress-bar .mat-progress-bar-fill::after {
        background: #FFA615;
        border-radius: 0 13px 13px 0;
    }
}

.app-my-current-progress-thumbnail-container {
    position: relative;

    .thumbnail-avatar {
        left: 10px; // be carefull change here need change in my-current-progress-thumbnail method : setMinAndMaxPourcentVzalueForProgressBar()
    }

    .thumbnail-next-win {
        right: 10px; // be carefull change here need change in my-current-progress-thumbnail method : setMinAndMaxPourcentVzalueForProgressBar()

        .imageRewardContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #FFFFFF;
            border: 4px solid #FED501;
            box-sizing: border-box;
            border-radius: 15px;
            width: 135px; // be carefull change here need change in my-current-progress-thumbnail method : setMinAndMaxPourcentVzalueForProgressBar()
            height: 135px;
            box-shadow: 0 15px 25px rgba(0, 0, 0, 0.06), 0 9px 10px rgba(0, 0, 0, 0.05), 0 5px 5px rgba(0, 0, 0, 0.04), 0 3px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.015), 0 0 0 rgba(0, 0, 0, 0.005), inset 0px 2px 2px rgba(255, 255, 255, 0.3);

            img {
                vertical-align: top;
                border: none;
                max-height: 90px;
                max-width: 90px;
                animation: movingGratification 1s infinite alternate;
            }

            .title {
                position: absolute;
            }
        }

        .noIconYet {
            padding: 0;
        }

        .rideau {
            background-color: #743f90;
            width: 127px;
            border-radius: 10px;
            height: 127px;
            position: absolute;
            top: 4px;
            left: 4px;
            transition: height 3s;


        }

        .rideau-open {
            background-color: #743f90;
            width: 127px;
            border-radius: 10px;
            height: 0;
            position: absolute;
            top: 4px;
            left: 4px;
        }

        .show-star-animation {
            &:before, &:after {
                content: '';
                background: transparent url('/assets/ubolino_app/icons/score-shine.svg') 50% 50%;
                background-size: 100% auto;
                width: 30px;
                height: 30px;
                display: block;
                position: absolute;
                top: 20px;
                left: 20px;
                animation: pulse;
                animation-duration: 2.5s;
                animation-delay: 0s;
                animation-iteration-count: infinite;
                transform: scale(0.5);
            }

            &:after {
                top: 85px;
                left: 85px;
                animation-duration: 3.5s;
                animation-delay: 0.3s;
            }
        }

        @keyframes movingGratification {
            from {
                transform: rotateZ(-10deg);
            }

            to {
                transform: rotateZ(10deg);
            }
        }
    }
}


app-my-current-progress-thumbnail {
    span {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #743F90;
        padding-top: 5px;
    }

    .avatar-value {
        padding-top: 13px;
    }

    position: absolute;
    top: -167px;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;

        mat-icon, app-dynamic {
            padding-top: 30px;
            background: #FFFFFF;
            border: 4px solid #FED501;
            box-sizing: border-box;
            border-radius: 15px;
            width: 135px; // be carefull change here need change in my-current-progress-thumbnail method : setMinAndMaxPourcentVzalueForProgressBar()
            height: 135px;
            box-shadow: 0 15px 25px rgba(0, 0, 0, 0.06), 0 9px 10px rgba(0, 0, 0, 0.05), 0 5px 5px rgba(0, 0, 0, 0.04), 0 3px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.015), 0 0 0 rgba(0, 0, 0, 0.005), inset 0px 2px 2px rgba(255, 255, 255, 0.3);

            canvas {
                transform: scale(1.5); // to show creature more bigger
            }
        }

        .spine-player {
            margin-top: -5px;
            height: 70px !important;
        }

        .star-animation {
            span {
                color: gold;
            }

            position: absolute;
            animation: starAnimation 2s infinite alternate;

            @keyframes starAnimation {
                from {
                    transform: rotateY(-30deg) scale(-2.7);
                }

                to {
                    transform: rotateY(30deg) scale(2.7);
                }
            }
        }

        .square {
            width: 5px;
            height: 10px;
            background-color: #FED501;
        }

        .avatar-square {
            width: 5px;
            height: 15px;
            background-color: #FED501;
        }


        .dot {
            width: 34px;
            height: 34px;
            background-color: #FED501;
            border-radius: 100%;
            box-shadow: 0 27px 40px rgba(0, 0, 0, 0.06), 0 10px 14px rgba(0, 0, 0, 0.06), 0 5px 8px rgba(0, 0, 0, 0.06), 0 3.2px 4.8px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.05), 0 0px 1px rgba(0, 0, 0, 0.045), inset 0 2px 2px rgba(255, 255, 255, 0.3);
        }

        .avatar-dot {
            width: 21px;
            height: 21px;
            background-color: #FED501;
            border-radius: 100%;
            box-shadow: 0 27px 40px rgba(0, 0, 0, 0.06), 0 10px 14px rgba(0, 0, 0, 0.06), 0 5px 8px rgba(0, 0, 0, 0.06), 0 3.2px 4.8px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.05), 0 0px 1px rgba(0, 0, 0, 0.045), inset 0 2px 2px rgba(255, 255, 255, 0.3);
        }

    }
}

app-my-statistic-progress {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    padding: 0px 20px 0 20px;

    .stat-row {
        display: flex;
        justify-content: space-between;
        padding: 5px;

        label {
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #743f90;
            padding-top: 3px;
        }

        span {
            width: 60px;
            background: #fed501;
            border-radius: 24px;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #743f90;
            padding: 5px 3px 3px 3px;
            border: 1px solid rgba(0, 93, 146, 0.05);
        }
    }
}

.btn-option {
    // todo until we fix css for activities in ubolinoo
    min-width: 50px;
    height: 60px;
    color: #fff;

    &.pristine {
        background: #743f90;
    }

    &.currently-correct {
        background: #89E30C;
    }

    &.was-correct {
        background: #89E30C !important;
        cursor: not-allowed;
    }

    &.incorrect {
        background: #000000;
    }

    &.missing {
        background: #000000;
    }
}

@import '_qcm-qcu';

app-short-answer {
    // todo change color of text for ubolinoo because it is white on white
    .mat-card mat-card-content.mat-card-content {
        color: #000;

        &.instruction-is-picture {
            align-items: center;

            .centeredContent, .latex-wrapper {
                max-width: 42vh;
            }
        }
    }

    .rb-question-text .renderedInputContent,
    .base {
        color: #005D92;
    }
}

@import 'fill-in-blanks';
@import 'summary-sub-lesson';

// @import "debug";


app-progress-multi-zone {
    padding: 20px 30px 24px 30px;
    display: block;

    .container {
        display: flex;
        box-shadow: inset 0px 2px 3px #0000002b;
        background-color: #e7f5fe;
        width: 100%;
        height: 15px;
        border-radius: 13px;
    }

    .zone {
        width: 0;
        transition: width 300ms ease 0s;
    }

    .trueAnswer {
        background-color: #89E30C;
    }

    .falseAnswer {
        background-color: #3A3A3A;
    }

    .isFirst {
        border-radius: 13px 0 0 13px;
    }

    .isLast {
        border-radius: 0 13px 13px 0;
    }

    .progression-cursor {
        background: #FED501;
        border: 1px solid rgba(0, 0, 0, 0.12);
        box-sizing: border-box;
        box-shadow: 0 3px 7px -3px rgba(0, 0, 0, 0.41), inset 0 4px 4px rgba(255, 255, 255, 0.25), inset 0px 0px 2px #FFA800;
        border-radius: 8.3633px;
        width: 10px;
        height: 30px;
        margin-top: -7px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .limitMax {
        width: 20%;
        transition: width 300ms ease 0s;
    }
}


app-end-screen-score {
    label {
        opacity: 0;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #743F90;
        display: block;
        animation: fadeIn;
        animation-duration: 2s;
        animation-delay: 1s;

        mat-icon {
            vertical-align: text-top;
            margin-left: -6px;
        }

        &:nth-child(1).animate {
            animation: stretch-bounce .5s ease-in-out;
            animation-delay: 1s;
        }

        &:nth-child(2).animate {
            animation: stretch-bounce 1s ease-in-out;
            animation-delay: 3s;
        }

        &:nth-child(3).animate {
            animation: stretch-bounce 1.5s ease-in-out;
            animation-delay: 3s;
        }

        &.hidden {
            opacity: 0 !important;

            mat-icon {
                opacity: 0 !important;
            }
        }
    }

    ul {
        list-style: none;
        text-align: center;
        margin: auto;
        padding: 0;

        li {
            display: inline-block;
            position: relative;

            &.star {
                div {
                    content: url('/assets/ubolino_app/icons/score-star.svg');
                    width: 54px;
                    height: 54px;

                }

                &.colored div {
                    content: url('/assets/ubolino_app/icons/score-star-colour.svg');
                }
            }

            &.diamond {
                div {
                    content: url('/assets/ubolino_app/icons/score-diamond.svg');
                    width: 85px;
                    height: 63px;
                }

                &:before, &:after {
                    content: '';
                    background: transparent url('/assets/ubolino_app/icons/score-shine.svg') 50% 50%;
                    background-size: 100% auto;
                    width: 30px;
                    height: 30px;
                    display: block;
                    position: absolute;
                    top: 25px;
                    left: 10px;
                    animation: pulse;
                    animation-duration: 2.5s;
                    animation-delay: 0s;
                    animation-iteration-count: infinite;
                    transform: scale(0.5);
                }

                &:after {
                    top: 49px;
                    left: 38px;
                    animation-duration: 3.5s;
                    animation-delay: 0.3s;
                }
            }

            &:nth-child(1).animate {
                animation: stretch-bounce 0.8s ease-in-out;
            }

            &:nth-child(2).animate {
                animation: stretch-bounce 1.6s ease-in-out;
            }

            &:nth-child(3).animate {
                animation: stretch-bounce 1.4s ease-in-out;
            }
        }
    }
}

@keyframes stretch-bounce {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes pulse {
    from {
        transform: scale3d(1, 1, 1);
    }

    50% {
        transform: scale3d(1.45, 1.45, 1.45);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}

app-reward-page, app-reward-unlocked {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    .title {
        color: #743F90;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        padding-top: 20px;
    }

    button {
        @extend %button-image;
        @extend %button-half;
        @extend %button-half-purple;
        border: none;
        margin: 30px auto 0 auto !important;
        justify-content: center;
        color: white;
        width: 50%;
        height: auto;
        padding: 0 20px 3.2% 20px;

        //color: white;
        //font-style: normal;
        //font-weight: bold;
        //font-size: 22px;
        //line-height: 26px;
        //text-align: center;
        //width: 100%;
        //height: 73px;
        //background-repeat: no-repeat;
        //background-position-x: center;
        //transform: scale(0.75); // because png not at good ratio cut if change
    }
}

app-reward-unlocked {
    .container {
        min-height: 500px;
        justify-content: space-around;
    }

    .imageRewardContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        border: 4px solid #FED501;
        box-sizing: border-box;
        border-radius: 15px;
        width: 200px;
        height: 200px;
        box-shadow: 0 15px 25px rgba(0, 0, 0, 0.06), 0 9px 10px rgba(0, 0, 0, 0.05), 0 5px 5px rgba(0, 0, 0, 0.04), 0 3px 3px rgba(0, 0, 0, 0.02), 0 1px 2px rgba(0, 0, 0, 0.015), 0 0 0 rgba(0, 0, 0, 0.005), inset 0px 2px 2px rgba(255, 255, 255, 0.3);

        img {
            vertical-align: top;
            border: none;
            max-height: 90px;
            max-width: 90px;
            animation: movingGratification 1s infinite alternate;
        }
    }
}

@import "_keyboard";

%app-zone-generator {
    app-zone-generator {

        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        opacity: 0.5;

        .generated-column {
            width: 100%;
            padding: 1px;

            .generated-row-element {
                width: 100%;
                height: 25%; // default value for 4 * 4 element in matrix
                padding: 1px;

                .colored-zone {
                    border-radius: 3px;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;

                    &.selected-true {
                        background-color: #89E30C;
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    &:active {
                        background-color: #3A3A3A !important;
                        opacity: 0.5;
                    }
                }
            }

        }

    }
}

app-image-zoning {
    .container {
        position: relative;
        width: 100%;
        height: auto;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 3px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        img {
            width: 100%;
            height: auto;
            border-radius: 3px !important;
        }
    }

    .available-answers {
        .container {
            border: solid;
            border-color: #743F90;
            border-radius: 7px;

            &.false-answer {
                border-color: #3A3A3A;
            }

            &.true-answer {
                border-color: #89E30C;
            }

        }
    }

    @extend %app-zone-generator;

}

.help_close_modal {
    mat-dialog-container.mat-dialog-container {
        .mat-dialog-title {
            h2 {
                font-weight: bold;
                font-size: 18px;

                &::first-letter {
                    text-transform: uppercase;
                }
            }

            button.close {
                display: none;
            }
        }

        .mat-dialog-content {
            mat-icon[svgicon="sound_off"] {
                margin-top: 10px;
            }
        }

        .mat-dialog-actions {
            padding-bottom: 13px;

            .mat-button, .mat-raised-button {
                font-size: 18px;
            }
        }
    }

}

/**
 on surcharge le body pour remettre la valeur par defaut
 dans le cas ou on est en player, le contenu (content) doit être en top:0
 */
body.is-player-active, body.is-login-or-register-active {
    mat-sidenav-content.mat-sidenav-content > .content.position-top-lower-for-content {
        top: 0;
    }
}

.button-animation-delay {
    opacity: 0;

    -webkit-transition: opacity 1s ease-in;
    -moz-transition: opacity 1s ease-in;
    -o-transition: opacity 1s ease-in;
    -ms-transition: opacity 1s ease-in;
    transition: opacity 1s ease-in;
}

.button-animation-delay.button-ready {
    opacity: 1;
}

app-multimedia {
    .text {
        color: #005D92;
    }
}

.is-mobile {
    app-short-answer,
    app-fill-in-blanks {
        .mat-card,
        .mat-card-content {
            overflow: inherit !important;
        }
    }
}

.tts-reading {
    color: #0fa9ff !important;
}


