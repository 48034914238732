$captchaHeight: "60px";

// https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
app-register-easly, fuse-forgot-password {
    width: 100%;
}


// DOM Level 0 -------------------------------------------------------------------------
fuse-login, fuse-register, fuse-forgot-password {
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-width: inherit;
    overflow: auto;
}

// DOM Level 1 ---------------------------------------------

// GENERAL -------------- Commons elements
#login,
#forgot-password,
#register,
#register-easly {
    padding: 0 0 70px 0;
    flex: 1;
    display: flex;

    // LOGO -------------
    .logo {
        margin: 0 !important;
        width: 100% !important;
        flex: 1;
        display: flex;
        align-items: center;
        padding-top: 40px;
        flex-direction: column;
        justify-content: flex-end;

            .mat-icon {
                width: 100%;
                height: auto;
                min-width: inherit;
                min-height: inherit;
                line-height: inherit;
            }
    }


    a {
        text-decoration: underline;
    }

    .title {
        font: #{$fuse-authentication-title-font};
        letter-spacing: #{$fuse-authentication-title-letter-spacing};
        line-height: 1em;
        color: #743F90;
        flex: 1;
    }

    button {
        padding: 0;
        margin: 0 auto;
        background-color: transparent;
        box-shadow: none;
        line-height: 1.2em !important;

        .mat-button-wrapper {
            @extend %button-image;
            @extend %button-half;
            @extend %button-half-green-up;
            padding-top: 5px;
            padding-bottom: 15px;
            justify-content: center;
            white-space: pre-wrap;
        }

        &.submit-button {
            width: inherit !important;
            max-width: 170px;
            min-width: 150px;
            //margin-top: 0;
            //margin-bottom: 0;
            margin: 0;
            @include media-breakpoint-up('sm') {
                //width: 35% !important;
            }

            &:disabled {
                opacity: 0.40;
                background-color: transparent;

                span {
                    cursor: not-allowed;
                }
            }

        }
    }

    .go-login-link-wrapper {
        margin: 0;
        flex-direction: row !important;
    }
}

// DOM Level 2 ------------------------------
#login-form-wrapper, #forgot-password-form-wrapper,
#register-form-wrapper, #register-easly-form-wrapper {
    justify-content: start !important;
    padding: 0 !important;
    box-shadow: none;
    display: flex;
    flex: 1;
    background: none;
    @include media-breakpoint('xs') {
        padding: 0 !important;
    }
}

// DOM Level 3 ------------------------------ FORM
#login-form,
#forgot-password-form,
#register-form,
#register-easly-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 !important;


    color: $fuse-authentication-color;
    background: $fuse-authentication-background !important;
    box-shadow: none !important;

    @include media-breakpoint('xs') {
        padding: 0 !important;
    }


    form {

        display: flex;
        flex-direction: column;



        // DOM form element level 0 -------
        .mat-form-field {
            padding: 0 5px;
            max-width: 350px;
            @include media-breakpoint-up('sm') {

            }
        }

        // DOM form element level 1 -------
        .mat-form-field-wrapper {
            padding: 0 !important;
            .mat-form-field-infix {
                padding: 0 15px !important;
            }
        }

        // DOM form element level 2 -------
        .mat-form-field-flex {
            margin-top: 0;
            background: transparent url('~./assets/ubolino_app/images/backgrounds/input_bg.svg') no-repeat center center;
            background-size: 100% auto;
            border: none;
            padding: 0 10px 0 10px;


            MIN-HEIGHT: 65PX;
            @include media-breakpoint-up('sm') {
                MIN-HEIGHT: 80PX;
            }
            display: flex;
            align-items: center;

        }

        // |____ DOM form element level 3 -------
        .mat-form-field-infix {
            padding: 22px 15px;
            border: none;
        }
        // |____ DOM form element level 4 -------
        //
        //  ______________________________________ //
        // |                                     | //
        // |                                     | //
        // --------------------------------------  //
        //
        .mat-input-element, .mat-select {
            font: #{$fuse-authentication-field-input-font};
            font-size: 18px;
            background-color: transparent !important;
            color: black;

            margin-bottom: 4px;
            margin-top: 6px;
        }

        //      LABEL ... PLACEHOLDER ...
        //     A B C D .....

        .mat-form-field-label-wrapper
        {
            padding: 0;
            top: -0.5em;
            font-size: 18px;

            .mat-form-field-label {
                text-transform: inherit;
                padding: 0 10px;
            }
        }
        // When Label Placeholder get up
        .mat-form-field-can-float.mat-form-field-should-float {
            .mat-form-field-label-wrapper {
                top: -1.4em;
                padding: 0;
                @include media-breakpoint-up('sm') {
                    top: -1.3em;
                }

                .mat-form-field-label {
                    transform: translateY(-0.9em) scale(0.7);
                    color: white;
                    font-weight: 400;
                    background-color: #fed501;
                    display: block;
                    width: auto;
                    padding-top: 4px;
                    border-top-left-radius: 7px;
                    border-top-right-radius: 7px;
                }
            }

        }


        // DOM form element level 2 -------
        .mat-form-field-underline {}

        .mat-form-field-label {
            color: black;
            font-weight: 700;
            transition: color 0.5s, font-weight 0.5s;
        }

        .mat-checkbox {
            margin: 20px 0;
            @include media-breakpoint-up('sm') {
                margin: 5px 0 10px 0;
            }

            .mat-checkbox-layout {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;

                font-size: 14px;
                white-space: pre-wrap;
                @include media-breakpoint-up('sm') {
                    width: 70% !important;
                    margin: 0 auto;
                }

                .mat-checkbox-inner-container { // la case
                    flex: none; // ne s'étend pas
                }

                .mat-checkbox-label {
                    display: flex;
                    flex-wrap: wrap; // les enfants vont à la ligne
                    flex: 1; // j'occupe tout l'espace dispo
                }
            }
        }

        jhi-re-captcha {
            margin-top: 20px;
        }

        .mat-checkbox-frame {
            border-color: map_get($mat-fusedark, 100);
        }

        .mat-form-field-prefix, .mat-form-field-suffix {
                align-self: center;
                padding: 10px 25px 0 0;
            }

        /* Change the white to any color ;) */
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        input:-internal-autofill-selected {
            background-color: transparent !important;
            background-image: none !important;
            color: -internal-light-dark-color(black, transparent) !important;
            padding: 0;
        }

        .mat-form-field-label {

        }

        .mat-form-field-underline,
        .mat-form-field-ripple,
        .mat-focused .mat-form-field-ripple,
        .mat-form-field-underline,
        .mat-form-field-ripple,
        .mat-focused .mat-form-field-ripple {
            margin-top: 5px;
            background-color: transparent;
        }

        .mat-form-field-subscript-wrapper {
            position: relative;
            margin-top: 0;
            margin-bottom: 0.54166667em;
        }

    } // /Form



}



// ~~~ specific others or not tidy


#login, app-register-easly {

    div#login-form-wrapper { // TODO supprimer de default pour s'en passer ici

        .mat-form-field { order: 0; }
        > div{ order: 2; }
        > button { order: 1; }
        > .link { order: 3; cursor: pointer; }
        > .forgot-password { order: 4; }

        .register .link, .register .text {
            color: #1ad0ad;
        }

    }

}

#register, #forgot-password-form {

    .logo + div {
        flex: 4;
        display: flex;
        flex-direction: column;
        padding-bottom: #{$captchaHeight};
    }
    .title {
        margin-bottom: auto;
    }
    form {
        flex: 5;
        margin-top: auto;
        flex-direction: column !important;
        justify-content: center;
        flex-wrap: nowrap !important;
        align-items: center;


        .mat-form-field {
            padding: 0 5px;
        }

        .mat-checkbox-label {
            color: #333;
            line-height: 18px;
        }
    }
}

#login {
    display: flex;
    #login-form-wrapper {
        justify-content: start !important;
    }
}

#register {
    //margin-bottom: 60px;
    form .mat-form-field-can-float.mat-form-field-should-float {
        .mat-form-field-label-wrapper {
            top: -1.5em;
            padding: 0;
            @include media-breakpoint-up('sm') {
                top: -1.7em;
            }
        }
    }
    .form-footer {
        align-items: center !important;
    }
}


#forgot-password-form {
    width: 483px;
    max-width: 100%;
    .title {
        flex: 0 !important;
    }
    .go-login-link-wrapper {
        margin: 0 !important;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100% !important;
    }
}

#login-form, #forgot-password, #register-easly {

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 4 0;
        justify-content: center;

        .mat-form-field { order: 0; }
        > div{ order: 2; }
        > button { order: 1; }


        > .link { order: 3; cursor: pointer;}
        > .forgot-password { order: 4; }
    }

    .register {
        margin: 0 !important;
        flex: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start !important;

        // padding-bottom: #{$captchaHeight};
    }

    .link, .forgot-password {
        color: #1ad0ad;
    }
}

#register-easly {
    form {
        flex: 1 !important;
    }
}

.register .link, .register .text {
    color: #1ad0ad;
}

app-register-easly {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up('lg') {
        padding-bottom: 60px;
    }

    .title {
        flex-direction: row;
        margin: 0px 0 32px 0 !important;
        display: flex;
        align-content: center;
        justify-content: center;
    }
    .title, .info {
        font-style: normal !important;
        font-weight: bold !important;
        font-size: 22px !important;
        line-height: 26px !important;
        text-align: center !important;
        color: #743F90 !important;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .info-container {
        flex: 0;
        visibility: visible;
    }

    .info {
        font-size: 18px !important;
        line-height: 22px !important;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        box-shadow: 0px 9px 34px -12px rgba(0, 0, 0, 0.06);
        border-radius: 7px;
        padding: 30px;
        margin-bottom: 0;

        &.info-code {
            border-radius: 7px 7px 0 0;
        }

        .info-pseudo {
            color: #0FA9FA;
        }

        .info-code-part-2, .info-code-part-3 {
            font-size: 14px;
            line-height: 17px;
        }
    }

    .step {
        color: white;
        background: #743F90;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        box-shadow: 0px 9px 34px -12px rgba(0, 0, 0, 0.06);
        border-radius: 7px;
        position: relative;
        top: 0;
        transform: translateY(50%);
        margin: auto;
        padding: 5px;
        width: 50px;
    }

    .user-code {
        background: #0FA9FA;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        box-shadow: 0 9px 34px -12px rgba(0, 0, 0, 0.06);
        border-radius: 0 0 7px 7px;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        padding: 15px;
        color: #FFFFFF;
        span{
            opacity: 0.5
        }
    }

    .class-code-error {
        color: #f44336;
    }

    form {
        flex-direction: column !important;
        .field-youAre {
            padding-top: 10px;

            button.submit-button {
                padding: 0;
            }
        }
    }

    .submit-button-wrapper {

    }
}
app-manage-connect-page {
    display: flex;
    height: 100%;
    flex-direction: column;
    width: 100%;
}
#register, app-register-easly,
app-quick-connect-page {
    display: flex;
    height: 100%;
    #register-easly-form,
    #login #login-form-wrapper #login-form {
        align-items: stretch;

        .logo { flex: 0; }
        .title { flex: 0; }
        .localUser { flex: 0; }

        .logo, h2.title {
            .mat-icon { padding: 0 20%; }
        }

        .localUser {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            align-items: center;

            button {
                flex-grow: 0 !important;
                width: 100%;
                padding: 0 !important;
                @extend %button-big;
                @extend %button-image;
                @extend %button-image-big-white;
                @extend %button-image-title;

                &.logout span.mat-button-wrapper {
                    color: #17D0AD;
                    .mat-icon {
                        height: 20px !important;
                    }
                }

                span.mat-button-wrapper {
                    justify-content: flex-start;
                    background-image: none !important;
                    padding: 0 0 0 6% !important;
                    color: #743F90;
                    &:before {
                        display: none;
                    }

                    mat-icon {
                        position: absolute;
                        top: 44%;
                        right: 7%;
                        height: 24px !important;
                        width: 24px !important;
                        color: #FF616F;
                        transform: translateY(-50%);

                        [svgicon="btn_log_out"] {
                            height: 27px !important;
                            width: 27px !important;
                        }


                        &.bigger {
                            height: 37px !important;
                            width: 37px !important;
                            right: 4%;
                        }
                    }

                }

                &.btn_delete {
                    @extend %button-image-wide-red;
                }

            }
        }

        ul {
            list-style-type: none;
            padding: 30px 0 0 0;
            li {
                padding-bottom: 20px;
            }
        }
    }

    .button{
        cursor: pointer;
    }
}
#login {
    #login-form-wrapper, #login-sso-wrapper, #login-code-wrapper {
            .title {
                // font-size: 20px; must be set with material
                margin: 0px 0 32px 0 !important;
            }
    }
}